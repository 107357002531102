import React, { Fragment } from 'react';
import { Row } from '@bootstrap-styled/v4';
import BuildYourCXInfo2 from '../buildyourCX-info2/buildyourCX-info2';
import SubHeading from '../buildyourCX-subheading/buildyourCX-subheading';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import { baseURL } from 'shared-components/theme/helpers';

const Step6 = ({ onSelect, selected }) => {
    return (
        <Wrapper>
            <BuildYourCXInfo2
                image={`${baseURL}assets/images/satisfaction.jpg`}
            />
            <SubHeading
                content={
                    <Fragment>
                        Are you interested in sending post-contact 5-Star <br />
                        customer surveys to measure customer satisfaction?
                    </Fragment>
                }
            />
            <Row>
                <CenterAligned>
                    <Button
                        onClick={() => onSelect('Yes')}
                        active={
                            selected &&
                            selected.value &&
                            selected.value === 'Yes'
                                ? true
                                : false
                        }>
                        Yes
                    </Button>
                    <Button
                        onClick={() => onSelect('No')}
                        active={
                            selected &&
                            selected.value &&
                            selected.value === 'No'
                                ? true
                                : false
                        }>
                        No
                    </Button>
                    <Button
                        onClick={() => onSelect('Maybe')}
                        active={
                            selected &&
                            selected.value &&
                            selected.value === 'Maybe'
                                ? true
                                : false
                        }>
                        Maybe
                    </Button>
                </CenterAligned>
            </Row>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    height: 100%;
    width: 100%;
`;

const CenterAligned = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    ${Media('mobile')} {
        display: block;
        padding: 0px 10%;
        max-height: calc(100vh - 450px);
        overflow: auto;
    }
`;
const Button = styled.div`
    height: 50px;
    width: 10%;
    border: 1px solid ${colors.black['100']};
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0px 5px;
    transition: all 0.2s ease-in-out;
    &:hover {
        background: ${colors.black['100']};
        color: ${colors.white['100']};
    }
    ${(props) => {
        if (props.active) {
            return `background:${colors.black['100']};
                    color:${colors.white['100']};`;
        } else {
            return `background:tranparent;
                    color:${colors.pink['100']}`;
        }
    }};
    ${Media('xlscreens')} {
        height: 2.6vw;
    }
    ${Media('tabletlandscape')} {
        width: 20%;
    }
    ${Media('mobile')} {
        width: 100%;
        margin: 10px 0px;
    }
`;
export default Step6;
