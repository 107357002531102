import React from 'react';
import { baseURL } from 'shared-components/theme/helpers';
import { Col, Row } from '@bootstrap-styled/v4';
import colors from 'shared-components/theme/colors';
import styled from 'styled-components';
import { gilroyBold } from 'shared-components/theme/fonts';

import { Para } from '../cx-pocket-guide/para';
import List from './list';
import { BlockImg } from '../imperative-cx/block-img';

export const Point5 = () => {
    return (
        <Wrapper>
            <Para regular>
                Call Silence Ratio Analytics analyzes the amount of silence on
                customer calls, which can help shed insight on how processes
                work, environmental factors at play, how accessible information
                is to agents, how calls are structured, and overall performance.
                By optimizing this call metric, you can ensure calls are both
                cost-efficient, and time-efficient for both the agent and the
                customer.
            </Para>
            <Para noMarginBottom regular>
                Your business intelligence team will:
            </Para>
            <List>
                <li>
                    Collect and analyze call data to identify&nbsp;
                    <b>common periods of silence</b> on customer calls.
                </li>
                <li>
                    Identify trends regarding when agents take more time – this
                    may point to agent assist tools needed, better processes,
                    gaps in training, or individualized coaching opportunities.
                </li>
                <li>
                    Pinpoint areas where calls can be shortened – this may be an
                    exercise in amending agent scripting, implementing
                    simulators, tool/system consolidation, or process
                    enhancement.
                </li>
                <li>
                    Help to <span>improve CSAT</span> when customer calls are
                    solved more efficiently and effectively.
                </li>
            </List>
            <Row>
                <Col lg="6">
                    <BlockImg
                        imgUrl={`${baseURL}assets/images/ebook/bi-methodologies/block-img-5.jpg`}
                    />
                </Col>
                <Col lg="6">
                    <BlockImg
                        imgUrl={`${baseURL}assets/images/ebook/bi-methodologies/block-img-6.jpg`}
                    />
                </Col>
            </Row>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    p {
        span {
            background-color: ${colors.pink['15']};
            font-family: ${gilroyBold};
        }
    }
`;
