import React, { useState } from 'react';
import { Link } from 'react-scroll';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroyMedium } from 'shared-components/theme/fonts';
import { FlexCentered } from 'shared-components/theme/grid-helpers';
import Media from 'shared-components/theme/medias';

export const EbookMenu = ({
    mainTheme,
    mainTheme2,
    data,
    pdfFile,
    bgColor,
}) => {
    const [open, setOpen] = useState(false);

    const downloadDocIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="43"
            viewBox="0 0 27 43">
            <g
                id="Group_635"
                data-name="Group 635"
                transform="translate(-1862 -1807)">
                <path
                    id="Icon_awesome-file-pdf"
                    data-name="Icon awesome-file-pdf"
                    d="M12.79,14.007c-.352-1.125-.345-3.3-.141-3.3C13.24,10.709,13.184,13.3,12.79,14.007Zm-.12,3.319a32.444,32.444,0,0,1-2,4.409,25.891,25.891,0,0,1,4.423-1.54A9.108,9.108,0,0,1,12.67,17.326ZM6.054,26.1c0,.056.928-.38,2.454-2.827A9.718,9.718,0,0,0,6.054,26.1ZM17.438,11.25H27V41.313A1.683,1.683,0,0,1,25.313,43H1.688A1.683,1.683,0,0,1,0,41.313V1.688A1.683,1.683,0,0,1,1.688,0H15.75V9.563A1.692,1.692,0,0,0,17.438,11.25Zm-.562,8.08a7.057,7.057,0,0,1-3-3.783c.316-1.3.816-3.277.436-4.514a1.761,1.761,0,0,0-3.361-.478c-.352,1.287-.028,3.1.57,5.414A66.025,66.025,0,0,1,8.648,22c-.007,0-.007.007-.014.007-1.905.977-5.175,3.129-3.832,4.781a2.185,2.185,0,0,0,1.512.7c1.259,0,2.51-1.266,4.3-4.345a40.079,40.079,0,0,1,5.555-1.631,10.656,10.656,0,0,0,4.5,1.371,1.82,1.82,0,0,0,1.385-3.052c-.977-.956-3.818-.682-5.175-.506ZM26.508,7.383,19.617.492A1.686,1.686,0,0,0,18.422,0H18V9h9V8.571A1.682,1.682,0,0,0,26.508,7.383ZM21.3,21.334c.288-.19-.176-.837-3.009-.633C20.9,21.812,21.3,21.334,21.3,21.334Z"
                    transform="translate(1862 1807)"
                    fill="#f4f5f9"
                />
                <path
                    id="Icon_metro-file-download"
                    data-name="Icon metro-file-download"
                    d="M17.478,10.061H15.055V6.427H11.421v3.635H9l4.24,4.24ZM9,15.513v1.212h8.481V15.513Z"
                    transform="translate(1862.002 1828.648)"
                    fill={mainTheme}
                />
            </g>
        </svg>
    );

    return (
        <>
            <Wrapper bgColor={bgColor}>
                <MenuWrapper>
                    <MenuTop>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="33.333"
                            viewBox="0 0 25 33.333">
                            <path
                                id="Icon_awesome-bookmark"
                                data-name="Icon awesome-bookmark"
                                d="M0,33.333V3.125A3.125,3.125,0,0,1,3.125,0h18.75A3.125,3.125,0,0,1,25,3.125V33.333L12.5,26.042Z"
                                fill={mainTheme}
                            />
                        </svg>
                    </MenuTop>
                    <MenuList mainTheme={mainTheme}>
                        {data &&
                            data.length > 0 &&
                            data.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <CustLink
                                            color={mainTheme2}
                                            navType={item.type}
                                            activeClass="active"
                                            to={item.target}
                                            spy={true}
                                            smooth={true}
                                            duration={1000}>
                                            <span className="count">
                                                {item.serial_number}
                                            </span>
                                            <span className="text">
                                                {item.title}
                                            </span>
                                        </CustLink>
                                    </li>
                                );
                            })}
                    </MenuList>
                    <DownloadBook>
                        <a href={pdfFile} download>
                            <DownloadIcon>{downloadDocIcon}</DownloadIcon>
                            <DownloadText color={mainTheme2}>
                                DOWNLOAD THE PDF
                            </DownloadText>
                        </a>
                    </DownloadBook>
                </MenuWrapper>
            </Wrapper>

            {/* Mobile Menu Start */}
            <MobileMenuWrapper>
                <MenuBtn
                    className="menuOpener"
                    mainTheme={mainTheme}
                    open={open}
                    onClick={() => setOpen(true)}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="93"
                        height="106.762"
                        viewBox="0 0 93 106.762">
                        <g transform="translate(-1827 -222)">
                            <g transform="translate(0 -554)">
                                <path
                                    d="M10,0H93a0,0,0,0,1,0,0V105a0,0,0,0,1,0,0H10A10,10,0,0,1,0,95V10A10,10,0,0,1,10,0Z"
                                    transform="translate(1827 776)"
                                    fill={bgColor}
                                />
                                <line
                                    x2="27"
                                    transform="translate(1861.5 882.637)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeWidth="0.25"
                                    opacity="0.649"
                                />
                            </g>
                            <path
                                d="M18.105,31.3l-1.561,1.561a1.681,1.681,0,0,1-2.384,0L.492,19.2a1.681,1.681,0,0,1,0-2.384L14.161,3.143a1.681,1.681,0,0,1,2.384,0L18.105,4.7a1.689,1.689,0,0,1-.028,2.412L9.6,15.188H29.813A1.683,1.683,0,0,1,31.5,16.875v2.25a1.683,1.683,0,0,1-1.687,1.688H9.6l8.473,8.072A1.677,1.677,0,0,1,18.105,31.3Z"
                                transform="translate(1858.003 257.354)"
                                fill={colors.white['100']}
                            />
                        </g>
                    </svg>
                </MenuBtn>
                <MenuBtn
                    mainTheme={mainTheme}
                    open={open}
                    onClick={() => setOpen(false)}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </MenuBtn>
                <InnerWrapper open={open} bgColor={bgColor}>
                    <DownloadBookMobile>
                        <a href={pdfFile} download>
                            <DownloadIcon>{downloadDocIcon}</DownloadIcon>
                            <DownloadText color={mainTheme2}>
                                DOWNLOAD THE PDF
                            </DownloadText>
                        </a>
                    </DownloadBookMobile>
                    <Menu mainTheme={mainTheme}>
                        {data &&
                            data.length > 0 &&
                            data.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <CustLink
                                            color={mainTheme2}
                                            navType={item.type}
                                            activeClass="active"
                                            to={item.target}
                                            spy={true}
                                            smooth={true}
                                            duration={1000}
                                            onClick={() => setOpen(false)}>
                                            <span className="count">
                                                {item.serial_number}
                                            </span>
                                            <span className="text">
                                                {item.title}
                                            </span>
                                        </CustLink>
                                    </li>
                                );
                            })}
                    </Menu>
                </InnerWrapper>
            </MobileMenuWrapper>
        </>
    );
};

const MenuWrapper = styled.div``;

const Wrapper = styled.div`
    position: fixed;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    z-index: 9999;
    background-color: ${(props) =>
        props.bgColor ? props.bgColor : colors.ebook.text};
    border-radius: 10px 0 0 10px;
    overflow: hidden;
    width: 90px;
    transition: all 330ms ease;
    &:hover {
        width: 500px;
    }
    ${Media('xlscreens')} {
        width: 4.6vw;
        &:hover {
            width: 26vw;
        }
    }
    ${Media('tablet')} {
        display: none;
    }
`;

const MenuTop = styled(FlexCentered)`
    padding: 30px 0px;
    width: 90px;
    ${Media('xlscreens')} {
        width: 4.6vw;
        padding: 1.5vw 0vw;
        svg {
            width: 1.25vw;
        }
    }
`;

const MenuList = styled.ul`
    padding: 0px;
    margin: 0px;
    li {
        a {
            color: ${colors.white['100']};
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
                height: 44px;
                font-family: ${gilroyMedium};
            }
            .count {
                flex: 0 0 90px;
                max-width: 90px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                font-size: 20px;
                &:after {
                    content: '';
                    position: absolute;
                    width: 30%;
                    height: 1px;
                    background-color: ${colors.white['100']};
                    opacity: 0.65;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
            .text {
                flex: 1 0;
                display: block;
                line-height: 44px;
                min-width: 0;
                padding-left: 20px;
                padding-right: 20px;
                font-size: 16px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &.active {
                span {
                    color: ${colors.white['100']};
                }
                .count {
                    background-color: ${(props) => props.mainTheme};
                    &:after {
                        display: none;
                    }
                }
                .text {
                    background-color: ${(props) => props.mainTheme}50;
                }
            }
        }
        &:last-child {
            a {
                .count {
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
    ${Media('xlscreens')} {
        li {
            a {
                span {
                    height: 2.3vw;
                }
                .count {
                    flex: 0 0 4.6vw;
                    max-width: 4.6vw;
                    font-size: 1vw;
                    &:after {
                        width: 1.1vw;
                    }
                }
                .text {
                    padding-left: 1.1vw;
                    padding-right: 3vw;
                    font-size: 0.83vw;
                    line-height: 2.3vw;
                }
            }
        }
    }
    ${Media('mobile')} {
        li {
            a {
                span {
                    font-size: 14px;
                }
                .count {
                    flex: 0 0 80px;
                    max-width: 80px;
                }
                .text {
                    padding-left: 7px;
                }
            }
        }
    }
`;

const CustLink = styled(Link)`
    &&& {
        color: ${(props) =>
            props.navType === 'main' ? props.color : colors.white['100']};
    }
`;

const DownloadBook = styled.div`
    a {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`;

const DownloadIcon = styled(MenuTop)`
    flex: 0 0 90px;
    padding: 20px 0px;
    ${Media('xlscreens')} {
        flex: 0 0 4.6vw;
        padding: 0.9vw 0vw 1.2vw;
    }
`;

const DownloadText = styled.div`
    padding-left: 20px;
    padding-right: 20px;
    flex: 1 0;
    font-size: 16px;
    text-transform: uppercase;
    font-family: ${gilroyMedium};
    color: ${(props) => (props.color ? props.color : colors.white['100'])};
    ${Media('xlscreens')} {
        padding-left: 1.1vw;
        padding-right: 1.1vw;
        font-size: 0.83vw;
    }
`;

const MobileMenuWrapper = styled.div`
    display: none;
    ${Media('tablet')} {
        display: block;
    }
`;

const MenuBtn = styled.a`
    position: fixed;
    z-index: 9999;
    width: 65px;
    height: 65px;
    top: 10px;
    right: 10px;
    display: ${(props) => (props.open ? 'block' : 'none')};
    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 30px;
        background: ${(props) =>
            props.open ? colors.white['100'] : colors.gray_dark['100']};
        opacity: 1;
        left: calc(50% - 15px);
        margin-bottom: 5px;
        border-radius: 9px;
        transform: scale(1);
        transform: rotate(0deg);
        transition: all 0.3s ease-in-out;
        &:nth-child(1) {
            top: ${(props) => (props.open ? '31px' : '22px')};
            transform: ${(props) => (props.open ? 'scale(0)' : 'scale(1)')};
        }
        &:nth-child(2) {
            top: 31px;
            transform: ${(props) =>
                props.open ? 'rotate(-45deg)' : 'rotate(0deg)'};
        }
        &:nth-child(3) {
            top: 31px;
            transform: ${(props) =>
                props.open ? 'rotate(45deg)' : 'rotate(0deg)'};
        }
        &:nth-child(4) {
            top: 40px;
            transform: ${(props) => (props.open ? 'scale(0)' : 'scale(1)')};
        }
    }
    &.menuOpener {
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        display: ${(props) => (props.open ? 'none' : 'flex')};
        border-radius: 10px 0 0 10px;
        align-items: center;
        justify-content: center;
    }
`;

const InnerWrapper = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9998;
    width: 100%;
    height: 100%;
    background-color: ${(props) =>
        props.bgColor ? props.bgColor : colors.ebook.text};
    padding: 120px 30px 20px;
    box-sizing: border-box;
    transition: all 0.5s ease;
    transform: ${(props) =>
        props.open ? 'translateX(0)' : 'translateX(100%)'};
    ${Media('mobile')} {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 100px;
    }
`;

const Menu = styled(MenuList)`
    position: relative;
    overflow-y: scroll;
    height: 100%;
`;

const DownloadBookMobile = styled(DownloadBook)`
    position: absolute;
    top: 5px;
    left: 20px;
    z-index: 99;
    ${DownloadIcon} {
        flex: 0 0 50px;
        width: 50px;
        margin-left: 10px;
        ${Media('mobile')} {
            margin-left: -5px;
        }
    }
`;
