import React, { useState, useRef } from 'react';
import Layout from 'components/layout';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { Col, Container, Row } from '@bootstrap-styled/v4';
import { baseURL } from 'shared-components/theme/helpers';
import {
    gilroyBold,
    gilroyMedium,
    gilroyRegular,
    gilroySemibold,
    morisonLight,
} from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import { RowContentCenter } from 'shared-components/theme/grid-helpers';
import { BsClockFill } from 'react-icons/bs';
import { FaArrowDown } from 'react-icons/fa';
import { EbookFloatingImage } from 'components/ebook/common';
import { LetsDiscuss } from 'components/industries/common';
import {
    Point1,
    Point2,
    Point3,
    Point4,
    Point5,
    Point6,
    Point7,
} from 'components/ebook/top-BI-methodilogies';
import ScrollAnimation from 'react-animate-on-scroll';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { Para } from 'components/ebook/cx-pocket-guide/para';
import { BlockImg } from 'components/ebook/imperative-cx/block-img';
import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, Tween } from 'react-gsap';
import { Link } from 'react-scroll';

const TopBIMethodologies = () => {
    const [activeIndexes, setActiveIndex] = useState([]);

    const cxSections = [
        {
            heading: 'Glide Path Modeling',
            component: <Point1 active={activeIndexes.includes(0)} />,
        },
        {
            heading: 'Complexity Mapping',
            component: <Point2 active={activeIndexes.includes(1)} />,
        },
        {
            heading: 'Value Stream Mapping',
            component: <Point3 active={activeIndexes.includes(2)} />,
        },
        {
            heading: 'DSAT Analytics',
            component: <Point4 active={activeIndexes.includes(3)} />,
        },
        {
            heading: 'Call Silence Ratio Analytics',
            component: <Point5 active={activeIndexes.includes(4)} />,
        },
        {
            heading: 'Agent Quartile Management',
            component: <Point6 active={activeIndexes.includes(5)} />,
        },
        {
            heading: 'Dream Team Modeling',
            component: <Point7 active={activeIndexes.includes(6)} />,
        },
    ];

    const handleAccordion = (index) => {
        const arr = [...activeIndexes];
        if (arr && arr.includes(index)) {
            const filter = arr.filter((i) => i !== index);
            setActiveIndex(filter);
        } else {
            arr.push(index);
            setActiveIndex(arr);
        }
    };

    return (
        <Layout>
            <Intro>
                <RowContentCenter>
                    <SectionTag introTag>CX POCKET GUIDE</SectionTag>
                </RowContentCenter>
                <Breadcrumbs>Resources / Ebooks</Breadcrumbs>
                <IntroContent>
                    <Row>
                        <Col lg="5">
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOnce={true}>
                                <IntroLeft>
                                    <span>
                                        <BsClockFill /> 5 minute read
                                    </span>
                                    <h1>
                                        Top BI Methodologies Every&nbsp;
                                        <u>CX Program</u> <br />
                                        Must Have
                                    </h1>
                                    <p>
                                        Discover The Business Intelligence
                                        Structure&nbsp;
                                        <br />
                                        Behind Every Great CX Program.
                                    </p>
                                    <Link
                                        to={'detail'}
                                        activeClass="active"
                                        spy={true}
                                        smooth={true}
                                        duration={1000}
                                        offset={-100}>
                                        <button>
                                            Full Read <FaArrowDown />
                                        </button>
                                    </Link>
                                </IntroLeft>
                            </ScrollAnimation>
                        </Col>
                        <Col lg="7">
                            <IntroRight>
                                <IntroVideoWrapper>
                                    <iframe
                                        width="100%"
                                        height="100%"
                                        src="https://www.youtube.com/embed/twNEisUaQa4"
                                        title="Top BI Methodologies"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen></iframe>
                                    <SummaryBlock>
                                        <span>TLDR</span>
                                        <img
                                            src={`${baseURL}assets/images/ebook/icon-arrow-up.svg`}
                                            alt="-"
                                        />
                                    </SummaryBlock>
                                </IntroVideoWrapper>
                                <EbookFloatingImage
                                    image={`${baseURL}assets/images/ebook/bi-methodologies/intro-circle.svg`}
                                    width={700}
                                    height={700}
                                    top="-19.5%"
                                    zIndex="-1"
                                />
                            </IntroRight>
                        </Col>
                    </Row>
                </IntroContent>

                <Controller>
                    <Scene
                        triggerHook="onLeave"
                        duration="100%"
                        offset={'-1500%'}
                        indicators={false}>
                        <Timeline>
                            <Tween
                                from={{
                                    transform: 'rotate(0deg)',
                                }}
                                to={{
                                    transform: 'rotate(-270deg)',
                                }}>
                                <EbookFloatingImage
                                    image={`${baseURL}assets/images/ebook/cx-pocket-guide-round.svg`}
                                    width="130"
                                    height="130"
                                    alt="-"
                                    top="9%"
                                    left="41%"
                                    zIndex="9"
                                />
                            </Tween>
                        </Timeline>
                    </Scene>
                </Controller>
            </Intro>
            <Detail id="detail">
                <Container>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <DescPara>
                            Today’s contact center leaders know the importance
                            of a data-driven CX strategy. However, an
                            overwhelming 99% of contact centers&nbsp;
                            <span>
                                don’t think that their data intelligence
                                strategy currently meets business needs.
                            </span>
                            &nbsp; That said – even if you’ve invested in
                            technology, there is a good chance you aren’t seeing
                            the full potential of a robust Business Intelligence
                            operation – and it might be impacting your ROI and
                            overall CX success.
                        </DescPara>
                        <QouteBlock>
                            <h3>
                                For every new CX program, the importance of
                                hitting performance targets, quickly, cannot be
                                overstated. In this hyper-demand,
                                hyper-engagement economy, every customer
                                experience is critical to your bottom line.
                            </h3>
                            <h5>Source: Deloitte</h5>
                        </QouteBlock>
                    </ScrollAnimation>
                    <Row>
                        <Col lg="6">
                            <BlockImg
                                imgUrl={`${baseURL}assets/images/ebook/bi-methodologies/block-img-1.jpg`}
                            />
                        </Col>
                        <Col lg="6">
                            <TextBlock>
                                <Para regular>
                                    In order to maximize the impact of your
                                    data-driven insights on your CX program,
                                    optimize contact center efficiency, scale
                                    rapidly, and deliver an innovative,
                                    personalized customer experience, consider
                                    these BI methodologies for performance
                                    management and CX success.
                                </Para>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={true}>
                                    <QouteBlock>
                                        <h3>
                                            The average ROI for enterprises
                                            using&nbsp;
                                            <span>
                                                business intelligence
                                            </span>{' '}
                                            and analytics is 1300%.
                                        </h3>
                                        <h5>Source: Yellow Fin BI</h5>
                                    </QouteBlock>
                                </ScrollAnimation>
                            </TextBlock>
                        </Col>
                    </Row>
                </Container>
            </Detail>
            <CollapseWrapper>
                {cxSections &&
                    cxSections.length &&
                    cxSections.map((section, index) => {
                        const refer = useRef();
                        return (
                            <Accordion
                                key={index}
                                active={activeIndexes.includes(index)}
                                height={refer?.current?.clientHeight}>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOnce={true}>
                                    <AccHeader
                                        onClick={() => handleAccordion(index)}>
                                        <p>
                                            <span>
                                                {index < 9
                                                    ? '0' + (index + 1)
                                                    : index + 1}
                                            </span>
                                            {section?.heading}
                                        </p>
                                        {(activeIndexes.includes(index) && (
                                            <FaMinus />
                                        )) || <FaPlus />}
                                    </AccHeader>
                                </ScrollAnimation>

                                <AccBody>
                                    <InnerBody ref={refer}>
                                        <SectionNumber
                                            greaterNum={index && index > 8}
                                            active={activeIndexes.includes(
                                                index
                                            )}>
                                            {index < 9
                                                ? '0' + (index + 1)
                                                : index + 1}
                                        </SectionNumber>
                                        {section.component}
                                    </InnerBody>
                                </AccBody>
                            </Accordion>
                        );
                    })}
            </CollapseWrapper>
            <Conclusion>
                <Container>
                    <h2>Conclusion</h2>
                    <QouteBlock>
                        <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOnce={true}>
                            <h3>
                                A good BI program connects the dots between
                                operational teams and touchpoints, ensuring
                                multi-functional alignment and communication.
                                With these data-driven insights integrated into
                                your CX program, you will be empowered to
                                streamline processes, better manage performance,
                                have the flexibility to make changes in
                                real-time based on your customer needs, and have
                                the data that affords you to make informed
                                decisions that lead to overall better CX
                                outcomes.
                            </h3>
                        </ScrollAnimation>
                    </QouteBlock>
                </Container>
            </Conclusion>
            <LetsDiscuss
                heading="Ready to take your CX performance to the next level?"
                para="Let's get started."
                btnText="Contact us"
            />
        </Layout>
    );
};

const Intro = styled.div`
    position: relative;
    background-color: ${colors.top_bi_ebook['grey']};
    padding: 175px;
    padding-top: 0px !important;
    img {
        width: 100%;
    }
    ${Media('xlscreens')} {
        padding: 9.11vw;
    }
    ${Media('tablet')} {
        padding: 50px;
    }
    ${Media('mobile')} {
        padding: 15px;
    }
`;

const Breadcrumbs = styled.div`
    font-family: ${gilroyMedium};
    font-size: 18px;
    color: ${colors.top_bi_ebook['dark_grey']};
    ${Media('xlscreens')} {
        font-size: 0.93vw;
    }
    ${Media('mobile')} {
        font-size: 14px;
        margin-top: 30px;
    }
`;

const SectionTag = styled.div`
    font-size: 24px;
    font-family: ${gilroySemibold};
    background-color: ${colors.cx_pocket_guide['dark']};
    display: inline-flex;
    padding: 10px 25px;
    color: ${(props) => (props.color ? props.color : colors.white['100'])};
    text-transform: uppercase;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    ${Media('xlscreens')} {
        font-size: 0.8vw;
        padding: 0.52vw 1.3vw;
        border-bottom-left-radius: 0.53vw;
        border-bottom-right-radius: 0.53vw;
    }
    ${Media('tablet')} {
        font-size: 16px;
    }
    ${Media('mobile')} {
        font-size: 14px;
        padding: 10px 20px;
    }
`;

const IntroContent = styled.div`
    margin-top: 100px;
`;

const IntroLeft = styled.div`
    span {
        font-size: 20px;
        align-items: center;
        display: flex;
        color: ${colors.top_bi_ebook['dark_grey']};
        font-family: ${gilroyMedium};
        svg {
            margin-right: 10px;
        }
    }
    h1 {
        font-size: 60px;
        margin-top: 70px;
        font-family: ${gilroySemibold};
        letter-spacing: -1.9px;
        u {
            text-decoration: underline;
            text-decoration-color: ${colors.pink['100']};
        }
    }
    p {
        font-size: 24px;
        margin: 60px 0;
        font-family: ${gilroyMedium};
    }
    button {
        font-size: 20px;
        color: ${colors.white['100']};
        padding: 20px 40px;
        background-color: ${colors.top_bi_ebook['black']};
        font-family: ${gilroySemibold};
        cursor: pointer;
        border: none;
    }
    ${Media('xlscreens')} {
        span {
            font-size: 1.04vw;
        }
        h1 {
            font-size: 3.125vw;
            margin-top: 3.64vw;
        }
        p {
            font-size: 1.25vw;
            margin: 3.125vw 0;
        }
        button {
            font-size: 1.04vw;
            padding: 1.04vw 2.08vw;
            svg {
                margin-left: 0.53vw;
            }
        }
    }
    ${Media('tablet')} {
        padding-top: 60px;
        span {
            font-size: 16px;
        }
        h1 {
            font-size: 42px;
            margin-top: 40px;
        }
        p {
            font-size: 18px;
            margin: 40px 0;
            br {
                display: none;
            }
        }
        button {
            padding: 10px 20px;
            font-size: 16px;
        }
    }
    ${Media('mobile')} {
        h1 {
            font-size: 32px;
        }
        p {
            font-size: 16px;
        }
    }
`;

const IntroRight = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 0;
    ${Media('xlscreens')} {
        height: 100%;
    }
`;

const IntroVideoWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    iframe {
        border: none;
    }
    ${Media('xlscreens')} {
        height: 100%;
        iframe {
            position: relative;
            z-index: 9;
            border-radius: 5px;
        }
    }
    ${Media('tablet')} {
        margin-bottom: 0;
    }
    ${Media('mobile')} {
        margin-top: 50px;
    }
`;

const SummaryBlock = styled.div`
    position: absolute;
    right: -110px;
    top: -70px;
    z-index: 999;
    span {
        font-size: 20px;
        font-family: ${gilroyMedium};
    }
    img {
        width: 75px;
        margin: 10px auto;
    }
    ${Media('xlscreens')} {
        right: -0.72vw;
        top: -4.2vw;
        display: flex;
        span {
            font-size: 1.04vw;
        }
        img {
            width: 3.9vw;
            position: relative;
            transform: rotate(180deg) scaleX(-1);
            margin-left: 0.4vw;
        }
    }
    ${Media('tablet')} {
        display: none;
    }
`;

const Detail = styled.section``;

const DescPara = styled.p`
    font-size: 24px;
    line-height: 1.66;
    font-family: ${gilroyRegular};
    &::first-letter {
        font-size: 130px;
        float: left;
        line-height: 1;
        padding-right: 20px;
        font-family: ${morisonLight};
    }
    span {
        background-color: ${colors.pink['15']};
        text-decoration: underline;
    }
    ${Media('xlscreens')} {
        font-size: 1.25vw;
        &::first-letter {
            font-size: 6.77vw;
            padding-right: 1.04vw;
        }
    }
    ${Media('tablet')} {
        font-size: 16px;
        &::first-letter {
            font-size: 70px;
        }
    }
    ${Media('mobile')} {
        font-size: 14px;
        &::first-letter {
            font-size: 50px;
        }
    }
`;

const QouteBlock = styled.div`
    background-color: ${colors.gray_lightest['50']};
    padding: 5% 15%;
    margin: 60px 0;
    border-radius: 5px;
    h3 {
        font-size: 32px;
        text-decoration: underline;
        font-family: ${morisonLight};
        line-height: 1.87;
    }
    h5 {
        font-size: 24px;
        font-family: ${morisonLight};
    }
    span {
        background-color: ${colors.pink['15']};
    }
    ${Media('xlscreens')} {
        margin: 3.125vw 0;
        h3 {
            font-size: 1.66vw;
        }
        h5 {
            font-size: 1.25vw;
        }
    }
    ${Media('tablet')} {
        padding: 50px;
        h3 {
            font-size: 24px;
        }
        h5 {
            font-size: 18px;
        }
    }
    ${Media('mobile')} {
        padding: 30px;
        h3 {
            font-size: 20px;
        }
        h5 {
            font-size: 14px;
        }
    }
`;

const CollapseWrapper = styled(Container)``;

const AccHeader = styled.button`
    margin-bottom: 70px;
    position: relative;
    padding-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid ${colors.black['50']};
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    p {
        color: inherit;
        font-size: 34px;
        font-family: ${gilroyBold};
        margin: 0;
    }
    span {
        margin-right: 60px;
    }
    svg {
        height: 30px;
        width: 30px;
        color: ${colors.pink['100']};
    }
    &:hover {
        color: ${colors.pink['100']};
    }
    ${Media('xlscreens')} {
        margin-bottom: 2.4vw;
        padding-bottom: 1.3vw;
        p {
            font-size: 1.57vw;
        }
        span {
            margin-right: 3.125vw;
        }
        svg {
            height: 1.57vw;
            width: 1.57vw;
        }
    }
    ${Media('tablet')} {
        p {
            font-size: 20px;
            text-align: left;
        }
        svg {
            height: 20px;
            width: 20px;
        }
    }
    ${Media('mobile')} {
        p {
            font-size: 14px;
            max-width: 90%;
            text-align: left;
        }
        span {
            margin-right: 10px;
        }
    }
`;

const AccBody = styled.div`
    height: 0px;
    min-height: 0;
    transition: all 500ms ease 200ms;
    overflow: hidden;
`;

const Accordion = styled.div`
    position: relative;
    ${(props) => {
        if (props.active) {
            return `
                    ${AccBody}{
                        height:${
                            props.height ? props.height + 150 + 'px' : '500px'
                        };
                }
            `;
        }
    }}
`;

const InnerBody = styled.div``;

const SectionNumber = styled.h3`
    font-size: 350px;
    color: ${colors.pink['100']};
    font-family: ${gilroyRegular};
    position: absolute;
    left: -30%;
    top: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 500ms ease 200ms;
    letter-spacing: -15px;
    ${(props) => {
        if (props.active) {
            return `
                opacity:1;
                visibility:visible;
            `;
        }
    }}
    ${Media('xlscreens')} {
        font-size: 18vw;
        letter-spacing: -0.78vw;
        ${(props) => {
            if (props.greaterNum) {
                return `
                    left:-33%;
                `;
            }
        }}
    }
    ${Media('tablet')} {
        display: none;
    }
`;

const Conclusion = styled.section`
    h2 {
        font-size: 34px;
        font-family: ${gilroyBold};
    }
    h3 {
        text-decoration: none;
        text-align: center;
    }
    ${Media('xlscreens')} {
        h2 {
            font-size: 1.77vw;
        }
    }
    ${Media('mobile')} {
        h2 {
            font-size: 24px;
            margin-top: 0;
        }
        h3 {
            font-size: 18px;
        }
    }
`;

const TextBlock = styled.div`
    padding-left: 90px;
    ${Media('xlscreens')} {
        padding-left: 4.68vw;
    }
    ${Media('tablet')} {
        padding-left: 0;
    }
`;

export default TopBIMethodologies;
