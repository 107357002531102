import React, { Fragment } from 'react';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import { gilroyBold } from 'shared-components/theme/fonts';
import { handleUrl } from 'shared-components/theme/helpers';
import ReactHtmlParser from 'react-html-parser';

const IndustriesDropdown = ({ subMenu }) => {
    const MenuWrapper = ({ item }) => {
        return (
            <DropdownItem
                href={handleUrl(item.url)}
                haveSubMenu={item.children && item.children.length > 0}
                target={item.target ? item.target : '_self'}>
                <DropIcon>
                    <img
                        src={item.menu_icon}
                        height="20"
                        width="20"
                        alt={(item.menu_icon_alt && item.menu_icon_alt) || '-'}
                    />
                </DropIcon>
                <DropText>{ReactHtmlParser(item.title)}</DropText>
            </DropdownItem>
        );
    };
    const MenuWithSubMenu = ({ item }) => {
        return (
            <NestedMenuWrap>
                <MenuWrapper item={item} />
                <SubMenuWrap>
                    {item.children.map((subItem, index2) => {
                        return (
                            <DropdownSubItem
                                href={handleUrl(subItem.url)}
                                key={index2}
                                target={
                                    subItem.target ? subItem.target : '_self'
                                }>
                                <DropIcon>
                                    <img
                                        src={subItem.menu_icon}
                                        height="20"
                                        width="20"
                                        alt={
                                            (subItem.menu_icon_alt &&
                                                subItem.menu_icon_alt) ||
                                            '-'
                                        }
                                    />
                                </DropIcon>
                                <DropText color={colors.white['100']}>
                                    {ReactHtmlParser(subItem.title)}
                                </DropText>
                            </DropdownSubItem>
                        );
                    })}
                </SubMenuWrap>
            </NestedMenuWrap>
        );
    };
    return (
        <Wrapper>
            {subMenu &&
                subMenu.map((item, index) => {
                    return (
                        <Fragment key={index}>
                            {item.children && item.children.length > 0 ? (
                                <MenuWithSubMenu item={item} />
                            ) : (
                                <MenuWrapper item={item} />
                            )}
                        </Fragment>
                    );
                })}
        </Wrapper>
    );
};

const SubMenuWrap = styled.div`
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    max-height: 13.54vw;
    background-color: ${colors.technologyDropdown['purpleBg']};
    padding: 0 1.56vw;
    position: absolute;
    left: 23.54vw;
    top: 0;
    border-bottom: 6px solid ${colors.pink['100']};
    width: 23.54vw;
    box-shadow: 5px 10px 10px ${colors.black['10']};
    transform: translateX(-20px);
    min-height: 10.35vw;
    ${Media('xlscreens')} {
        width: 23.54vw;
    }
`;
const DropText = styled.div`
    font-size: 14px;
    font-family: ${gilroyBold};
    color: ${(props) => (props.color ? props.color : colors.black['100'])};
    letter-spacing: -0.5px;
    transition: all 0.3s ease;
    ${Media('xlscreens')} {
        font-size: 0.88vw;
        padding-left: 1.04vw;
    }
`;
const DropdownItem = styled.a`
    border-bottom: 1px solid ${colors.black['4']};
    display: flex !important;
    align-items: center;
    color: inherit;
    &:hover {
        ${DropText} {
            transform: translateX(5px);
        }
    }
    ${Media('xlscreens')} {
        padding: 1.04vw 0 !important;
        margin: 0 1.56vw;
    }
    ${(props) => {
        if (props.haveSubMenu) {
            return `
                &:before {
                    content: '';
                    position: absolute;
                    border-top: 0.35vw solid transparent;
                    border-left: 0.47vw solid ${colors.industriesDropdown['menuDropdownGrey']};
                    border-bottom: 0.35vw solid transparent;
                    right: 0;
                    top: 50%;
                    transform: translateY(-40%);
                }
            `;
        }
    }}
`;
const DropdownSubItem = styled(DropdownItem)`
    margin: 0 !important;
    width: 100%;
    border-bottom: 1px solid ${colors.white['8']};
`;
const NestedMenuWrap = styled.div`
    &:hover {
        ${DropdownItem}:before {
            border-left-color: ${colors.pink['100']};
        }
        ${SubMenuWrap} {
            visibility: visible;
            opacity: 1;
            transform: translateX(0px);
        }
    }
`;

const Wrapper = styled.div`
    width: 400px;
    display: flex;
    flex-direction: column;
    background-color: ${colors.industriesDropdown['bgColor']};
    border-bottom: 6px solid ${colors.pink['100']};
    box-shadow: 0px 10px 10px ${colors.black['10']};
    ${Media('xlscreens')} {
        width: 23.54vw;
    }
`;
const DropIcon = styled.div`
    width: 50px;
    display: flex;
    justify-content: center;
    img {
        max-height: 20px;
        max-width: 30px;
        height: auto;
        width: auto;
    }
    ${Media('xlscreens')} {
        width: 2.66vw;
        img {
            max-height: 1.24vw;
            max-width: 1.56vw;
            height: auto;
            width: auto;
        }
    }
`;

export default IndustriesDropdown;
