import React, { Fragment } from 'react';
import { Row } from '@bootstrap-styled/v4';

import BuildYourCXInfo2 from '../buildyourCX-info2/buildyourCX-info2';
import SubHeading from '../buildyourCX-subheading/buildyourCX-subheading';

import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import { baseURL } from 'shared-components/theme/helpers';

const Step6 = ({ onSelect, selected }) => {
    return (
        <Wrapper>
            <BuildYourCXInfo2
                image={`${baseURL}assets/images/programme-goal.jpg`}
            />
            <SubHeading
                content={<Fragment>What is the goal of your program?</Fragment>}
            />
            <Row>
                <CenterAligned>
                    <Button
                        onClick={() => onSelect('Branding')}
                        active={
                            selected &&
                            selected.value &&
                            selected.value === 'Branding'
                                ? true
                                : false
                        }>
                        Branding
                    </Button>
                    <Button
                        onClick={() => onSelect('Direct Response')}
                        active={
                            selected &&
                            selected.value &&
                            selected.value === 'Direct Response'
                                ? true
                                : false
                        }>
                        Direct Response
                    </Button>
                </CenterAligned>
            </Row>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    height: 100%;
    width: 100%;
`;

const CenterAligned = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    ${Media('mobile')} {
        display: block;
        padding: 0px 10%;
    }
`;
const Button = styled.div`
    height: 35px;
    min-width: 8%;
    border: 1px solid ${colors.gray_dark['100']};
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0px 5px;
    font-size: 14px;
    transition: all 0.2s ease-in-out;
    &:hover {
        background: ${colors.pink['100']};
        color: ${colors.white['100']};
        border: none;
    }
    ${(props) => {
        if (props.active) {
            return `background:${colors.pink['100']};
                    color:${colors.white['100']};
                    border:none`;
        } else {
            return `background:tranparent;`;
        }
    }};
    ${Media('xlscreens')} {
        font-size: 0.73vw;
        height: 1.84vw;
    }
    ${Media('tabletlandscape')} {
        width: 20%;
    }
    ${Media('mobile')} {
        width: 100%;
        margin: 10px 0px;
        height: 50px;
    }
`;
export default Step6;
