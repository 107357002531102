import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import { Row, Col } from '@bootstrap-styled/v4';
import Input from 'shared-components/input/input';
import TextArea from 'shared-components/textarea/textarea';
import Button from 'shared-components/button/button';
import { gilroyBold, gilroySemibold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import fetch from 'cross-fetch';

const ContactForm = ({
    bgColor,
    heading,
    subHeading,
    headingSize,
    color,
    padding,
    cx_demo,
    noForm,
}) => {
    const [first_name, set_first_name] = useState('');
    const [last_name, set_last_name] = useState('');
    const [company, set_company] = useState('');
    const [title, set_title] = useState('');
    const [email_address, set_email_address] = useState('');
    const [phone, set_phone] = useState('');
    const [comment, set_comment] = useState('');
    const [contact_msg_status, set_contact_msg_status] = useState('');
    const [contact_msg, set_contact_msg] = useState('');
    const [submit_btn_text, set_submit_btn_text] = useState('Send');

    const handleCxData = () => {
        if (cx_demo) {
            return `&type=CX-demo`;
        } else {
            return '';
        }
    };

    const submitContactForm = async (e) => {
        e.preventDefault();
        set_contact_msg_status('');
        set_contact_msg('');
        set_submit_btn_text('Sending...');

        try {
            const res = await fetch(
                `${process.env.REACT_APP_IBEX_API}/contact-form?token=${
                    process.env.REACT_APP_API_TOKEN
                }&first_name=${first_name}&last_name=${last_name}&email_address=${email_address}&organization=${company}&title=${title}&phone=${phone}&comment=${comment}${handleCxData()}`,
                { method: 'POST' }
            );
            if (res.status >= 400) {
                throw new Error('Bad response from server');
            }
            const resData = await res.json();
            if (resData && resData.status === 200) {
                set_contact_msg_status(resData.msg);
                set_contact_msg(resData.msg_details);
                set_first_name('');
                set_last_name('');
                set_company('');
                set_phone('');
                set_title('');
                set_email_address('');
                set_comment('');
                set_submit_btn_text('Send');
                setTimeout(() => {
                    set_contact_msg_status('');
                }, 10000);
            } else {
                set_contact_msg(result);
                set_submit_btn_text('Send');
            }
        } catch (err) {
            console.error(err);
            set_contact_msg(err.msg);
            set_submit_btn_text('Send');
        }
    };

    return (
        <Wrapper
            bgColor={bgColor}
            color={color}
            headingSize={headingSize}
            padding={padding}>
            <h3>
                {(heading && heading) || (
                    <Fragment>
                        Let’s talk about <br /> how we can help.
                    </Fragment>
                )}
            </h3>
            {subHeading && <SubHeading color={color}>{subHeading}</SubHeading>}
            {!noForm && (
                <form onSubmit={(e) => submitContactForm(e)} method="POST">
                    <Row>
                        <Col sm="6">
                            <Input
                                label="First Name"
                                name="first_name"
                                onChange={(val) =>
                                    set_first_name(val.target.value)
                                }
                                value={first_name}
                                color={color}
                            />
                        </Col>
                        <Col sm="6">
                            <Input
                                label="Last Name"
                                name="last_name"
                                onChange={(val) =>
                                    set_last_name(val.target.value)
                                }
                                value={last_name}
                                color={color}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <Input
                                label="Company"
                                name="company"
                                onChange={(val) =>
                                    set_company(val.target.value)
                                }
                                value={company}
                                color={color}
                            />
                        </Col>
                        <Col sm="6">
                            <Input
                                label="Job Title"
                                name="title"
                                onChange={(val) => set_title(val.target.value)}
                                value={title}
                                color={color}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <Input
                                label="Email"
                                name="email_address"
                                onChange={(val) =>
                                    set_email_address(val.target.value)
                                }
                                value={email_address}
                                color={color}
                            />
                        </Col>
                        <Col sm="6">
                            <Input
                                label="Phone"
                                name="phone"
                                color={color}
                                onChange={(val) => set_phone(val.target.value)}
                                value={phone}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <TextArea
                                label="Comments"
                                name="comment"
                                rows={3}
                                onChange={(val) =>
                                    set_comment(val.target.value)
                                }
                                value={comment}
                                color={color}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <FormBtn>
                                <Button
                                    bgColor={
                                        (color && color) || colors.black['100']
                                    }
                                    color={
                                        color && color === colors.white['100']
                                            ? colors.black['100']
                                            : colors.white['100']
                                    }
                                    ifButton={true}
                                    submit={false}>
                                    <b>{submit_btn_text}</b>
                                </Button>
                            </FormBtn>
                            {contact_msg_status != '' && (
                                <p>
                                    <b
                                        style={{
                                            textTransform: 'capitalize',
                                        }}>
                                        {contact_msg_status.toLowerCase()}
                                    </b>
                                    ! {contact_msg}
                                </p>
                            )}
                        </Col>
                    </Row>
                </form>
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    h3 {
        font-size: ${(props) =>
            props && props.headingSize ? props.headingSize : '39px'};
        font-family: ${gilroyBold};
        margin: 0;
        line-height: 1.2;
        color: ${(props) => props.color && props.color && props.color};
        span {
            color: ${colors.pink['100']};
        }
        ${Media('xlscreens')} {
            font-size: ${(props) =>
                props.headingSize ? props.headingSize : '2.9vw'};
        }
        ${Media('tablet')} {
            font-size: 30px;
            br {
                display: none;
            }
        }
    }
    ul {
        list-style-type: none;
        padding: 0px;
        margin: 0px;
        display: flex;
        align-items: center;
        li {
            margin-right: 25px;
            &:last-child {
                margin-right: 0;
            }
            a {
                font-size: 12px;
                font-family: ${gilroyBold};
                transition-duration: 0.3s;
                ${Media('xlscreens')} {
                    font-size: 1vw;
                }
            }
        }
        ${Media('tablet')} {
            margin-bottom: 30px;
        }
    }
    p {
        ${(props) => {
            if (props.color) {
                return `color: ${props.color}`;
            }
        }};
    }
    ${Media('tablet')} {
        padding: ${(props) => (props.padding ? '50px' : '0px')};
    }
`;
const SubHeading = styled.div`
    padding: 10px 0px;
    font-size: 18px;
    span {
        font-size: 20px;
        font-family: ${gilroySemibold};
        b {
            font-family: ${gilroyBold};
        }
    }
    ${Media('xlscreens')} {
        font-size: 0.94vw;
        padding: 1vh 0vh;
    }
    ${Media('tabletlandscape')} {
        br {
            display: none;
        }
    }
    ${(props) => {
        if (props && props.color) {
            return `color:${props.color}`;
        }
    }}
`;
const FormBtn = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 2em;
`;

export default ContactForm;
