import React from 'react';
import { Parallax } from 'react-scroll-parallax';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';

export const EbookFloatingImage = ({
    top,
    bottom,
    right,
    left,
    width,
    height,
    image,
    zIndex,
    rotate,
    circled,
    className,
    x,
    y,
    hideOnMobile,
}) => {
    return (
        <Wrapper
            top={top}
            bottom={bottom}
            right={right}
            left={left}
            width={width}
            height={height}
            zIndex={zIndex}
            rotate={rotate}
            circled={circled}
            hideOnMobile={hideOnMobile}
            className={className}>
            <Parallax x={x} y={y}>
                <img src={image} alt="-" />
            </Parallax>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    position: absolute;
    top: ${(props) => props.top};
    bottom: ${(props) => props.bottom};
    right: ${(props) => props.right};
    left: ${(props) => props.left};
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    z-index: ${(props) => (props.zIndex ? props.zIndex : '99')};
    transform: ${(props) => props.rotate};
    border-radius: ${(props) => props.circled && props.width}px;
    div {
        width: inherit;
        height: inherit;
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    ${Media('xlscreens')} {
        width: ${(props) => props.width / 19.2}vw;
        height: ${(props) => props.height / 19.2}vw;
    }
    ${Media('tablet')} {
        display: none;
    }

    ${Media('mobile')} {
        &.m {
            top: -8%;
        }
    }
`;
