import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroyBold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';

export const EbookMainHeading = ({
    children,
    centerAligned,
    color,
    noMargin,
    size,
    className,
    lineHeight,
}) => {
    return (
        <Wrapper
            centerAligned={centerAligned}
            color={color}
            noMargin={noMargin}
            size={size}
            lineHeight={lineHeight}
            className={className}>
            {children}
        </Wrapper>
    );
};

const Wrapper = styled.h2`
    font-size: ${(props) => (props.size === 'small' ? '36px' : '50px')};
    font-family: ${gilroyBold};
    color: ${(props) => (props.color ? props.color : colors.ebook.text)};
    margin: ${(props) => (props.noMargin ? '0' : '20px 0 60px')};
    text-align: ${(props) => (props.centerAligned ? 'center' : 'left')};
    line-height: ${(props) => props.lineHeight && props.lineHeight};
    span {
        color: ${colors.pink['100']};
    }
    ${Media('xlscreens')} {
        font-size: ${(props) => (props.size === 'small' ? '2.34vw' : '3.38vw')};
        margin: ${(props) => (props.noMargin ? '0' : '1vw 0 3vw')};
    }
    ${Media('mobile')} {
        font-size: 42px;
        margin-bottom: ${(props) => (props.noMargin ? '0' : '40px')};
        br {
            display: none;
        }

        &.m {
            font-size: 38px;
        }
    }
`;
