import React from 'react';
import { Col, Row } from '@bootstrap-styled/v4';

import { Para } from '../cx-pocket-guide/para';
import styled from 'styled-components';
import List from './list';
import { BlockImg } from '../imperative-cx/block-img';
import Media from 'shared-components/theme/medias';
import { baseURL } from 'shared-components/theme/helpers';

export const Point1 = () => {
    return (
        <Wrapper>
            <Row>
                <Col lg="6">
                    <TextBlock>
                        <Para regular noMarginTop>
                            A glide path is a planning and forecasting analysis
                            that helps you to optimize service levels and labor
                            costs by analyzing a range of factors. It can show
                            the impacts of new hires and their time to
                            proficiency affecting the all-in performance. This
                            will help you to predict and plan ahead of periods
                            of burst and peak activity, as well as when to scale
                            back as things slow down, ensuring you’re hitting
                            service levels seamlessly, and cost-effectively.
                        </Para>
                    </TextBlock>
                </Col>
                <Col lg="6">
                    <BlockImg
                        imgUrl={`${baseURL}assets/images/ebook/bi-methodologies/block-img-1.svg`}
                    />
                </Col>
            </Row>
            <Para regular noMarginBottom>
                Your business intelligence team will:
            </Para>
            <List>
                <li>
                    <span>
                        Identify all possible factors that might impact service,
                    </span>
                    &nbsp;using historical data, past performance trends, call
                    triggers, and peak contact periods.
                </li>
                <li>
                    <span>Analyze trends</span>
                    &nbsp;from contact center data, while taking into account
                    costs, goals, objectives, and recruitment needs.
                </li>
                <li>
                    Pinpoint the resources needed based on this analysis, such
                    as the number of agents at specific intervals of peaks and
                    seasonal ramps.
                </li>
                <li>
                    Identify best practices during ramp/seasonal impacts for
                    future glide path modeling.
                </li>
            </List>
        </Wrapper>
    );
};

const Wrapper = styled.div``;

const TextBlock = styled.div`
    padding-right: 90px;
    ${Media('xlscreens')} {
        padding-right: 4.68vw;
    }
    ${Media('tablet')} {
        padding-right: 0;
        margin-top: 30px;
    }
`;
