import React, { Fragment } from 'react';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import { gilroyMedium, gilroyBold } from 'shared-components/theme/fonts';
import { handleUrl } from 'shared-components/theme/helpers';
import ReactHtmlParser from 'react-html-parser';

const SolutionDropdown = ({ subMenu }) => {
    return (
        <Fragment>
            {subMenu &&
                subMenu.map((item, ind) => {
                    return (
                        <DropdownItem
                            href={handleUrl(item.url)}
                            key={ind}
                            target={
                                item.target === '_blank' ? item.target : '_self'
                            }>
                            <DropImg>
                                <img
                                    src={item.menu_image}
                                    height="150"
                                    width="100"
                                    alt={
                                        (item.menu_image_alt &&
                                            item.menu_image_alt) ||
                                        '-'
                                    }
                                />
                            </DropImg>
                            <DropText>
                                <DropTextLeft>
                                    <DropIcon>
                                        <img
                                            src={item.menu_icon}
                                            width="22"
                                            height="22"
                                            alt={
                                                (item.menu_icon_alt &&
                                                    item.menu_icon_alt) ||
                                                '-'
                                            }
                                        />
                                    </DropIcon>
                                    <DropLeftDesc>
                                        <DropTextTop>
                                            {ReactHtmlParser(item.title)}
                                        </DropTextTop>
                                        <DropTextMiddle
                                            color={item.bulletColor}>
                                            {ReactHtmlParser(item.main_heading)}
                                        </DropTextMiddle>
                                        <DropTextBottom>
                                            {ReactHtmlParser(item.subheading)}
                                        </DropTextBottom>
                                    </DropLeftDesc>
                                </DropTextLeft>
                                <DropTextRight title={item.title}>
                                    {ReactHtmlParser(item.menu_description)}
                                </DropTextRight>
                            </DropText>
                        </DropdownItem>
                    );
                })}
        </Fragment>
    );
};
const DropImg = styled.div`
    position: relative;
    height: 150px;
    width: 100%;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition-duration: 0.3s;
        opacity: 1;
        transform: scale(1);
    }
    ${Media('xlscreens')} {
        height: 8.5vw;
    }
`;

const DropdownItem = styled.a`
    width: 375px;
    display: flex;
    flex-direction: column;
    height: 305px;
    background-color: ${colors.gray_dark['100']};
    ${Media('xlscreens')} {
        width: 22.2vw;
        height: 18vw;
    }
    &:hover {
        ${DropImg} {
            img {
                opacity: 1;
                transform: scale(1.2);
            }
        }
    }
`;

const DropIcon = styled.div`
    background-color: ${colors.gray_darker['50']};
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    margin-left: 30px;
    margin-top: -20px;
    img {
        width: 60%;
        position: absolute;
    }
    ${Media('xlscreens')} {
        width: 2.25vw;
        height: 2.25vw;
        margin-left: 1.57vw;
        margin-top: -1.047vw;
        img {
            width: 1.12vw;
            height: 1.12vw;
        }
    }
`;

const DropText = styled.div`
    align-self: flex-start;
    display: flex;
    width: 100%;
    flex: 1;
    div {
        color: ${colors.white['100']};
    }
`;
const DropTextLeft = styled.div`
    width: 50%;
`;
const DropLeftDesc = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding-left: 40px;
    ${Media('xlscreens')} {
        padding-left: 2.047vw;
    }
`;
const DropTextRight = styled.div`
    width: 50%;
    background-color: ${colors.white['white_lightest']};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    ul {
        padding: 0px;
        li {
            display: flex;
            margin: 5px 0px;
            align-items: center;
            font-size: 9px;
            &::before {
                content: '';
                height: 3px;
                width: 3px;
                border-radius: 50%;
                margin-right: 10px;
                ${(props) => {
                    if (props && props.title) {
                        if (props.title === 'ibex. Connect') {
                            return `background-color:${colors.pink['100']}`;
                        } else if (props.title === 'ibex. Digital') {
                            return `background-color:${colors.orange['100']}`;
                        } else {
                            return `background-color:${colors.blue['100']}`;
                        }
                    }
                }}
            }
            ${Media('xlscreens')} {
                margin: 0.26vw;
                font-size: 0.58vw;
                &::before {
                    height: 0.15vw;
                    width: 0.15vw;
                    margin-right: 0.523vw;
                }
            }
        }
    }
`;
const DropTextTop = styled.div`
    font-size: 12px;
    font-family: ${gilroyMedium};
    sup {
        font-size: 9px;
        position: relative;
        top: -5px;
    }
    ${Media('xlscreens')} {
        font-size: 0.73vw;
        margin-top: 0.7vw;
        sup {
            font-size: 0.35vw;
            top: -0.1vw;
        }
    }
`;

const DropTextMiddle = styled.div`
    font-size: 14px;
    font-family: ${gilroyBold};
    margin: 10px 0px;
    line-height: 1.3;
    white-space: nowrap;
    ${(props) => {
        if (props.color) {
            return `span{
                color:${props.color};
            }`;
        }
    }};
    span {
        font-size: 16px;
    }
    ${Media('xlscreens')} {
        font-size: 0.85vw;
        margin: 0.52vw 0px;
        span {
            font-size: 0.83vw;
        }
    }
`;

const DropTextBottom = styled.div`
    font-size: 9px;
    ${Media('xlscreens')} {
        font-size: 0.6vw;
    }
`;
export default SolutionDropdown;
