import React, { Fragment } from 'react';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import { gilroySemibold } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';
import ReactHtmlParser from 'react-html-parser';

const TechnologyDropdown = ({ subMenu }) => {
    return (
        <Fragment>
            <WaveXLogo>
                <img
                    src={`${baseURL}assets/images/sol-feat-icon-4.svg`}
                    alt="Multiple X"
                    height="40"
                    width="40"
                />
            </WaveXLogo>
            {subMenu &&
                subMenu.map((item, ind) => {
                    return (
                        <DropdownItem
                            href={item.url}
                            key={ind}
                            target={item.target ? item.target : '_self'}>
                            <DropTop>
                                <DropIcon>
                                    <img
                                        src={item.menu_icon}
                                        height="15"
                                        width="20"
                                        alt={
                                            (item.menu_icon_alt &&
                                                item.menu_icon_alt) ||
                                            '-'
                                        }
                                    />
                                </DropIcon>
                                <DropHeading>
                                    {ReactHtmlParser(item.title)}
                                </DropHeading>
                            </DropTop>
                            <DropBottom>
                                {ReactHtmlParser(item.main_heading)}
                            </DropBottom>
                        </DropdownItem>
                    );
                })}
        </Fragment>
    );
};
const WaveXLogo = styled.div`
    height: 65px;
    width: 65px;
    background-color: ${colors.technologyDropdown['dark']};
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 20px;
    img {
        height: 40px;
        width: 40px;
    }
    ${Media('xlscreens')} {
        height: 3.4vw;
        width: 3.4vw;
        border-bottom-left-radius: 1.04vw;
        img {
            height: 2.094vw;
            width: 2.094vw;
        }
    }
`;
const DropIcon = styled.div`
    background-color: ${colors.technologyDropdown['box']};
    width: 75px;
    height: 75px;
    border-radius: 10px;
    display: flex;
    justify-self: center;
    align-items: center;
    justify-content: center;
    box-shadow: 3px 3px 3px ${colors.black['10']};
    transition: all 0.3s ease;
    img {
        height: 15px;
        width: auto;
    }
    ${Media('xlscreens')} {
        border-radius: 0.52vw;
        width: 3.92vw;
        height: 3.92vw;
    }
`;
const DropHeading = styled.div`
    text-align: center;
    color: ${colors.gray_darker['100']};
    margin-top: 20px;
    font-family: ${gilroySemibold};
    font-size: 14px;
    ${Media('xlscreens')} {
        margin-top: 1.04vw;
        font-size: 0.82vw;
    }
`;
const DropdownItem = styled.a`
    width: 235px;
    border-right: 1px solid ${colors.white['100']};
    display: flex;
    flex-direction: column;
    height: 265px;
    background-color: ${colors.white['100']};
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    box-shadow: 0px 3px 10px ${colors.black['10']};
    margin-right: 3px;
    margin-bottom: 10px;
    ${Media('xlscreens')} {
        width: 12.5vw;
        height: 15vw;
        border-bottom-right-radius: 1.04vw;
        border-bottom-left-radius: 1.04vw;
    }
    &:hover {
        ${DropIcon} {
            transform: scale(1.1);
        }
    }
`;
const DropTop = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 70%;
`;
const DropBottom = styled.div`
    flex: 1;
    background-color: ${colors.technologyDropdown['light']};
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 12px;
    width: 100%;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    color: ${colors.gray_darker['100']};
    ${Media('xlscreens')} {
        border-bottom-right-radius: 1.04vw;
        border-bottom-left-radius: 1.04vw;
        font-size: 0.73vw;
    }
`;
export default TechnologyDropdown;
