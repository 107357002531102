import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';

const Loader = ({ size, position }) => {
    return size && size === 'small' ? (
        <SmallWrapper position={position}>
            <Wrapper>
                <div className="centered">
                    <div className="blob-1"></div>
                    <div className="blob-2"></div>
                </div>
            </Wrapper>
        </SmallWrapper>
    ) : (
        <MainWrapper position={position}>
            <Wrapper>
                <div className="centered">
                    <div className="blob-1"></div>
                    <div className="blob-2"></div>
                </div>
            </Wrapper>
        </MainWrapper>
    );
};

const Wrapper = styled.div`
    .centered {
        width: 400px;
        height: 400px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: transparent;
    }
    .blob-1,
    .blob-2 {
        width: 30px;
        height: 30px;
        position: absolute;
        background: #fff;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        ${Media('xlscreens')} {
            width: 2vw;
            height: 2vw;
        }
    }
    .blob-1 {
        left: 20%;
        animation: osc-l 2.5s ease infinite;
        background: ${colors.pink['50']};
    }
    .blob-2 {
        left: 80%;
        animation: osc-r 2.5s ease infinite;
        background: ${colors.pink['100']};
    }
    @keyframes osc-l {
        0% {
            left: 20%;
        }
        50% {
            left: 50%;
        }
        100% {
            left: 20%;
        }
    }
    @keyframes osc-r {
        0% {
            left: 80%;
        }
        50% {
            left: 50%;
        }
        100% {
            left: 80%;
        }
    }
`;

const MainWrapper = styled.div`
    height: calc(100vh - 82px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: ${(props) => props.position};
    ${Wrapper} {
        margin-top: 100px;
    }
`;

const SmallWrapper = styled.div`
    padding: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 200px;
    ${Wrapper} {
        .centered {
            width: 200px;
            height: 200px;
        }
        .blob-1,
        .blob-2 {
            ${Media('xlscreens')} {
                width: 1vw;
                height: 1vw;
            }
        }
    }
`;

export default Loader;
