import React from 'react';
import { Container, Row, Col } from '@bootstrap-styled/v4';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';

import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import { gilroyMedium } from 'shared-components/theme/fonts';
import { baseURL } from 'shared-components/theme/helpers';

const BuildYourCXHeader = ({ headerMenus, closeForm, hideHeaderData }) => {
    return (
        <Wrapper>
            <Container fluid={true}>
                <Row>
                    <Col lg="2" xs="8">
                        <Logo>
                            <img
                                src={`${baseURL}assets/images/ibex-logo.svg`}
                                alt="ibex logo red"
                            />
                        </Logo>
                    </Col>
                    <Col lg="9" xs="2">
                        {!hideHeaderData && (
                            <Menu>
                                {headerMenus &&
                                    headerMenus.length > 0 &&
                                    headerMenus.map((data, index) =>
                                        data.step === 'language' ||
                                        data.step === 'region' ||
                                        data.step === 'contact-center-type' ? (
                                            data.value &&
                                            data.value.length > 0 && (
                                                <MenuItem key={index}>
                                                    <a>
                                                        <span>
                                                            {data.value[0] &&
                                                            data.step !==
                                                                'language'
                                                                ? data.value[0]
                                                                : data.value[0]
                                                                      .label}
                                                        </span>
                                                        {data.value &&
                                                            data.value.length >
                                                                1 && (
                                                                <span>
                                                                    , +
                                                                    {data.value
                                                                        .length -
                                                                        1}
                                                                    more
                                                                </span>
                                                            )}
                                                    </a>
                                                </MenuItem>
                                            )
                                        ) : data.value ? (
                                            <MenuItem key={index}>
                                                <a>
                                                    {data.value && data.value}
                                                </a>
                                            </MenuItem>
                                        ) : (
                                            data.info &&
                                            (data.info.first_name !== '' ||
                                                data.info.last_name !== '' ||
                                                data.info.email !== '' ||
                                                data.info.phone_number !== '' ||
                                                data.info.organization !==
                                                    '') && (
                                                <MenuItem key={index}>
                                                    <a>
                                                        {data.info
                                                            .first_name !==
                                                            '' && (
                                                            <span>
                                                                {
                                                                    data.info
                                                                        .first_name
                                                                }
                                                                <span>
                                                                    &nbsp;
                                                                </span>
                                                            </span>
                                                        )}
                                                        {data.info.last_name !==
                                                            '' && (
                                                            <span>
                                                                {
                                                                    data.info
                                                                        .last_name
                                                                }
                                                            </span>
                                                        )}
                                                    </a>
                                                </MenuItem>
                                            )
                                        )
                                    )}
                            </Menu>
                        )}
                    </Col>
                    <Col lg="1" xs="2">
                        <CloseSliderIcon onClick={() => closeForm()}>
                            <MdClose />
                        </CloseSliderIcon>
                    </Col>
                </Row>
            </Container>
        </Wrapper>
    );
};
const Wrapper = styled.header`
    padding: 0px 60px;
    width: 100%;
    background-color: ${colors.gray_lighter['100']};
    z-index: 999;
    border-bottom: 1px solid ${colors.gray_dark['10']};
    height: 82px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    ${Media('xlscreens')} {
        height: 5.3vw;
        padding: 0px 5vw;
    }
    ${Media('desktopscreens')} {
        height: 5.8vw;
        padding: 0px 5vw;
    }
    ${Media('tablet')} {
        padding: 0px 10px;
    }
`;
const CloseSliderIcon = styled.div`
    font-size: 30px;
    color: ${colors.gray_dark['50']};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 100%;
    ${Media('xlscreens')} {
        font-size: 1.57vw;
    }
`;
const Logo = styled.a`
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    z-index: 1000;
    img {
        width: auto;
        height: 30px;
    }
    ${Media('xlscreens')} {
        padding-left: 3vw;
    }
    ${Media('mobile')} {
        img {
            height: 22px;
        }
    }
`;
const Menu = styled.div`
    margin-bottom: 0;
    display: flex;
    ${Media('tabletlandscape')} {
        padding: 0;
        width: 100%;
        margin-top: 50px;
        display: none;
    }
`;
const MenuItem = styled.div`
    display: flex;
    padding: 10px;
    height: 82px;
    position: relative;
    width: 110px;
    word-break: break-word;
    text-overflow: ellipsis;
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: ${colors.pink['100']};
        left: 0px;
        bottom: 0px;
        transition: all 0.5s ease;
    }
    a {
        display: block;
        align-items: center;
        padding: 0 0px;
        position: relative;
        color: ${colors.gray_dark['100']};
        font-family: ${gilroyMedium};
        font-size: 12px;
        sup {
            top: -5px;
        }
    }
    ${Media('xlscreens')} {
        height: 5.3vw;
        padding-left: 0.5vw;
        padding-right: 0.5vw;
        margin: 0px 1vw;
        width: 5.78vw;
        padding-top: 2vw;
        a {
            font-size: 0.6vw;
            sup {
                font-size: 0.5vw;
            }
        }
    }
    ${Media('desktopscreens')} {
        &:after {
            bottom: -1.1vw;
            height: 3px;
        }
        a {
            font-size: 1.1vw;
        }
    }
`;

export default BuildYourCXHeader;
