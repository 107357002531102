import React from 'react';
import { Row, Col } from '@bootstrap-styled/v4';

import BuildYourCXInfo2 from '../buildyourCX-info2/buildyourCX-info2';
import SubHeading from '../buildyourCX-subheading/buildyourCX-subheading';

import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import Input from 'shared-components/input/input';
import { baseURL } from 'shared-components/theme/helpers';

const Step7 = ({
    onSelect,
    details,
    inValidEmail,
    inValidCEmail,
    inValidPhone,
    emailNotMatched,
}) => {
    return (
        <Wrapper>
            <BuildYourCXInfo2 image={`${baseURL}assets/images/touch.jpg`} />
            <SubHeading content="Tell us a bit about yourself, so we can get in touch" />
            <FormFieldsWrapper>
                <Row>
                    <CenterAligned>
                        <Col lg="2">
                            <Input
                                value={details.info && details.info.first_name}
                                label="First Name"
                                name="first_name"
                                onChange={(val) =>
                                    onSelect(val.target.value, 'first_name')
                                }
                            />
                        </Col>
                        <Col lg="2">
                            <Input
                                value={details.info && details.info.last_name}
                                label="Last Name"
                                name="last_name"
                                onChange={(val) =>
                                    onSelect(val.target.value, 'last_name')
                                }
                            />
                        </Col>
                        <Col lg="2">
                            <Input
                                value={details.info && details.info.email}
                                label="Email"
                                name="email"
                                onChange={(val) =>
                                    onSelect(val.target.value, 'email')
                                }
                            />
                            <Error>
                                {inValidEmail &&
                                    'Please Enter a Valid Email Address'}
                            </Error>
                        </Col>
                    </CenterAligned>
                </Row>
                <Row>
                    <CenterAligned>
                        <Col lg="2">
                            <Input
                                value={
                                    details.info && details.info.confirm_email
                                }
                                label="Confirm Email"
                                name="confirm_email"
                                onChange={(val) =>
                                    onSelect(val.target.value, 'confirm_email')
                                }
                            />
                            {inValidCEmail && (
                                <Error>
                                    Please Enter a Valid Email Address <br />
                                </Error>
                            )}
                            {emailNotMatched && (
                                <Error>Email Not Matched</Error>
                            )}
                        </Col>
                        <Col lg="2">
                            <Input
                                value={
                                    details.info && details.info.phone_number
                                }
                                label="Phone Number"
                                name="phone_number"
                                onChange={(val) =>
                                    onSelect(val.target.value, 'phone_number')
                                }
                            />
                            <Error>
                                {inValidPhone &&
                                    'Please Enter a Valid Mobile Number'}
                            </Error>
                        </Col>
                        <Col lg="2">
                            <Input
                                value={
                                    details.info && details.info.organization
                                }
                                label="Organization"
                                name="organization"
                                onChange={(val) =>
                                    onSelect(val.target.value, 'organization')
                                }
                            />
                        </Col>
                    </CenterAligned>
                </Row>
            </FormFieldsWrapper>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    height: 100%;
    width: 100%;
`;

const CenterAligned = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 10px 0px;
    ${Media('tabletlandscape')} {
        display: block;
        margin: 0px 0px;
    }
`;
const Error = styled.span`
    font-size: 12px;
    color: ${colors.pink['100']};
`;
const FormFieldsWrapper = styled.div`
    ${Media('tabletlandscape')} {
        max-height: calc(100vh - 510px);
        overflow: auto;
        padding: 0px 30px;
    }
`;
export default Step7;
