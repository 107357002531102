import React from 'react';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';

export const EbookDoubleBgSection = ({
    children,
    firstBg,
    secondBg,
    firstBgHeight,
    noPadding,
}) => {
    return (
        <Wrapper
            firstBg={firstBg}
            firstBgHeight={firstBgHeight}
            secondBg={secondBg}
            noPadding={noPadding}
        >
            {children}
        </Wrapper>
    );
};

const Wrapper = styled.section`
    position: relative;
    z-index: 1;
    padding: ${(props) => props.noPadding && '0'};
    &:before,
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        top: 0px;
        left: 0px;
        z-index: -1;
    }
    &:before {
        height: 100%;
        background-color: ${(props) => props.secondBg};
    }
    &:after {
        background-color: ${(props) => props.firstBg};
        height: ${(props) =>
            props.firstBgHeight ? props.firstBgHeight : '50%'};
    }
    ${Media('tablet')} {
        padding: ${(props) => (props.noPadding ? '0' : '20px 15px')};
    }
`;
