import React from 'react';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import { sourceSansPro } from 'shared-components/theme/fonts';

export const EbookMainPara = ({
    children,
    color,
    noMargin,
    centerAligned,
    spanColor,
    size,
    noLetterSpacing,
    seasonal,
}) => {
    return (
        <Wrapper
            color={color}
            noMargin={noMargin}
            centerAligned={centerAligned}
            spanColor={spanColor}
            size={size}
            noLetterSpacing={noLetterSpacing}
            seasonal={seasonal}>
            {children}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    font-size: ${(props) => (props.size === 'large' ? '24px' : '20px')};
    line-height: ${(props) => (props.seasonal ? '1.6' : '1.8')};
    margin-top: 0px;
    font-family: ${sourceSansPro};
    letter-spacing: ${(props) => (props.noLetterSpacing ? '0' : '1px')};
    color: ${(props) => (props.color ? props.color : colors.ebook.text)};
    text-align: ${(props) => (props.centerAligned ? 'center' : 'left')};
    p {
        font-size: inherit;
        color: inherit;
        line-height: inherit;
        letter-spacing: 0;
        font-family: inherit;
        span {
            font-weight: bold;
            color: ${(props) => props.spanColor};
        }
        b {
            font-family: inherit;
        }
    }
    ${Media('xlscreens')} {
        font-size: ${(props) =>
            props.size === 'large'
                ? '1.45vw'
                : props.seasonal
                ? '1.05vw'
                : '1.25vw'};
        letter-spacing: ${(props) => (props.noLetterSpacing ? '0' : '0.07vw')};
        p {
            margin-bottom: ${(props) => (props.seasonal ? '1.6vw' : '2.2vw')};
            letter-spacing: 0;
        }
    }
    ${Media('desktopscreens')} {
        font-size: 1.1vw;
    }
    ${Media('tablet')} {
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;
