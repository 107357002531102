import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroyMedium } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';

export const Text = ({ children, white, bigText, lineHeight }) => {
    return (
        <Wrapper bigText={bigText} white={white} lineHeight={lineHeight}>
            {children}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    font-size: 14px;
    font-family: ${gilroyMedium};
    line-height: ${(props) => (props.lineHeight ? props.lineHeight : '1.7')};
    color: ${(props) => props.white && colors.white['100']};
    ${Media('xlscreens')} {
        font-size: ${(props) => (props.bigText ? '1.14vw' : '0.93vw')};
    }
    ${Media('tablet')} {
        br {
            display: none;
        }
    }
`;
