import React from 'react';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import colors from 'shared-components/theme/colors';
import {
    gilroyBold,
    gilroyMedium,
    gilroySemibold,
} from 'shared-components/theme/fonts';

const BuildYourCXInfo = ({ heading, para, branddetails, bigheading }) => {
    return (
        <Wrapper>
            <BigText bigheading={bigheading && bigheading}>{heading}</BigText>
            {para && <Para>{para}</Para>}
            {branddetails && <BrandDetails>{branddetails}</BrandDetails>}
        </Wrapper>
    );
};
const Wrapper = styled.div`
    background-color: ${colors.gray_lightest['100']};
    min-height: 325px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    ${Media('xlscreens')} {
        min-height: 17.1vw;
    }
    ${Media('tabletlandscape')} {
        min-height: 250px;
    }
    ${Media('mobile')} {
        min-height: 200px;
    }
`;
const BigText = styled.div`
    font-size: 46px;
    font-family: ${gilroyBold};
    padding-bottom: 40px;
    padding: 10px 10px 40px 10px;
    span {
        color: ${colors.pink['100']};
    }
    ${(props) => {
        if (props.bigheading) {
            return ` font-size:90px !important;
                     padding-bottom:0px !important;
                     font-family:${gilroySemibold};
                    ${Media('xlscreens')}{
                        font-size:5vw !important;
                        padding-bottom:0px !important;
                        line-height:5vw;
                    }
                    ${Media('tabletlandscape')}{
                        font-size:45px !important;
                        padding:0px 10px;
                    }
                    `;
        }
    }}
    ${Media('xlscreens')} {
        font-size: 2.42vw;
        padding: 0.52vw 0.52vw 2.21vw 0.52vw;
    }
    ${Media('tabletlandscape')} {
        text-align: center;
        padding: 10px 10px;
        font-size: 26px;
    }
`;
const Para = styled.div`
    font-family: ${gilroyMedium};
    text-align: center;
    padding: 20px 0px;
    font-size: 16px;
    span {
        font-family: ${gilroyBold};
    }
    ${Media('xlscreens')} {
        font-size: 0.84vw;
        padding: 1.05vw 0;
    }
    ${Media('mobile')} {
        padding: 10px 0px;
        width: 90%;
        br {
            display: none;
        }
    }
`;
const BrandDetails = styled.div`
    font-size: 10px;
    position: relative;
    left: calc(15%);
    span {
        font-family: ${gilroyBold};
        color: ${colors.pink['100']};
    }
    ${Media('xlscreens')} {
        font-size: 0.52vw;
    }
    ${Media('tabletlandscape')} {
        padding: 20px 0px;
    }
`;
export default BuildYourCXInfo;
