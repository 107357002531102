import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroyMedium, gilroySemibold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';

export const IndustrySectionHeading = ({
    children,
    bigText,
    noLetterSpacing,
    white,
    alignCentered,
}) => {
    return (
        <Wrapper
            bigText={bigText}
            noLetterSpacing={noLetterSpacing}
            white={white}
            alignCentered={alignCentered}>
            {children}
        </Wrapper>
    );
};

const Wrapper = styled.h2`
    font-size: ${(props) => (props.bigText ? '50px' : '44px')};
    font-family: ${(props) => (props.bigText ? gilroyMedium : gilroySemibold)};
    line-height: ${(props) => (props.bigText ? 1.3 : 1.38)};
    color: ${(props) => props.white && colors.white['100']};
    margin-bottom: ${(props) => props.bigText && '50px'};
    text-align: ${(props) => props.alignCentered && 'center'};
    letter-spacing: ${(props) => (props.noLetterSpacing ? '0' : '-1px')};
    ${Media('xlscreens')} {
        font-size: ${(props) => (props.bigText ? '2.9vw' : '2.3vw')};
        margin-bottom: ${(props) => props.bigText && '3.64vw'};
        letter-spacing: ${(props) => (props.noLetterSpacing ? '0' : '-0.08vw')};
    }
    ${Media('tablet')} {
        br {
            display: none;
        }
    }
    ${Media('mobile')} {
        font-size: 22px;
        text-align: center;
    }
`;
