import React from 'react';

import { Para } from '../cx-pocket-guide/para';
import styled from 'styled-components';
import List from './list';
import { Col, Row } from '@bootstrap-styled/v4';
import { BlockImg } from '../imperative-cx/block-img';
import { baseURL } from 'shared-components/theme/helpers';
import Media from 'shared-components/theme/medias';

export const Point6 = () => {
    return (
        <Wrapper>
            <Para regular>
                Agent Quartile Management measures employee performance
                categorically by quartiles. This helps identify your top
                performers, as well as those agents who need intervention, such
                as additional training and coaching. This will overall help
                optimize your performance management strategy by honing in on
                what, and who, to focus on for improvement. This also takes the
                best practice handling techniques of top performers to infuse
                into lower quartiles for performance optimization.
            </Para>
            <Para regular>Your business intelligence team will:</Para>
            <Row>
                <Col lg="6">
                    <BlockImg
                        imgUrl={`${baseURL}assets/images/ebook/bi-methodologies/block-img-6.svg`}
                    />
                </Col>
                <Col lg="6">
                    <ListWrapper>
                        <List>
                            <li>
                                Analyze agent performance data to rank each
                                agent into the correct quartile.
                            </li>
                            <li>
                                <span>Identify poor performers</span> in regard
                                to specific KPIs and further to work on their
                                skills to improve their performance.
                            </li>
                        </List>
                    </ListWrapper>
                </Col>
            </Row>
            <List>
                <li>
                    Find trends regarding top and bottom performers. For
                    example, if bottom quartile performers are largely from one
                    area of the contact center, it might point to a&nbsp;
                    <b>specific training or leadership issue</b> rather than the
                    individual agents.
                </li>
            </List>
        </Wrapper>
    );
};

const Wrapper = styled.div``;

const ListWrapper = styled.div`
    ${Media('xlscreens')} {
        margin-left: 1.5vw;
    }
`;
