import React from 'react';

import { Para } from '../cx-pocket-guide/para';
import styled from 'styled-components';
import List from './list';
import { BlockImg } from '../imperative-cx/block-img';
import { baseURL } from 'shared-components/theme/helpers';

export const Point2 = () => {
    return (
        <Wrapper>
            <Para regular>
                Complexity Mapping analyzes the time it takes to handle critical
                call types, what processes are involved, tools required to
                complete call type, customer sentiment, volatility indexing, and
                the incurred costs of a particular CX process. This will help
                you to pinpoint areas for improvement within your program,
                strengthen performance management capabilities, and achieve
                greater organizational effectiveness.
            </Para>
            <BlockImg
                imgUrl={`${baseURL}assets/images/ebook/bi-methodologies/block-img-2.jpg`}
            />
            <div style={{ marginTop: 50 }}>
                <Para regular noMarginBottom>
                    Your business intelligence team will:
                </Para>
                <List>
                    <li>
                        <b>Analyze customer data,</b> and extract trends from
                        customer interactions across the entire customer
                        journey.
                    </li>
                    <li>
                        Identify triggers and friction points that&nbsp;
                        <b>lead to customer contact and churn.</b>
                    </li>
                    <li>
                        Reduce complexity and volatility in processes, reduce
                        customer friction, deflect to self-service channels for
                        low complexity drivers” then put achieve greater
                        organizational effectiveness
                    </li>
                    <li>
                        Use Complexity Studies to drive channel strategy and
                        make data-driven recommendations to&nbsp;
                        <span>
                            reduce costs and improve program performance.
                        </span>
                    </li>
                </List>
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.div``;
