import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Row, Col } from '@bootstrap-styled/v4';
import {
    FaChartBar,
    FaUserAlt,
    FaCog,
    FaBuilding,
    FaBorderAll,
} from 'react-icons/fa';
import BuildYourCXInfo from '../buildyourCX-info/buildyourCX-info';
import BuildYourCXOption from '../buildyourCX-option/buildyourCX-option';
import SubHeading from '../buildyourCX-subheading/buildyourCX-subheading';
import Media from 'shared-components/theme/medias';

const Step2 = ({ onSelect, selected }) => {
    return (
        <Wrapper>
            <BuildYourCXInfo
                heading={
                    <Fragment>
                        Outsourcing<span>.</span>
                    </Fragment>
                }
                para={
                    <Fragment>
                        From <span>Startup, to Scale-Up</span>, to blue chip, we
                        love building expert CX teams for our clients.
                        <br />
                        <br />
                        To help us give you an accurate idea of how we can help
                        your brand, please provide some quick
                        <br />
                        details on the type of CX team you’d like to build.
                    </Fragment>
                }
            />
            <SelectionWrapper>
                <SubHeading content="What type of Contact Center team(s) are you looking to build?" />
                <Row>
                    <CenterAligned>
                        <Col lg="2" xs="12">
                            <BuildYourCXOption
                                label={<Fragment>Sales</Fragment>}
                                Icon={FaChartBar}
                                value="Sales"
                                onSelectOption={(value) => onSelect(value)}
                                active={
                                    selected &&
                                    selected.value.includes('Sales') && {
                                        value: 'Sales',
                                    }
                                }
                            />
                        </Col>
                        <Col lg="2" xs="12">
                            <BuildYourCXOption
                                label={
                                    <Fragment>
                                        Customer <br /> Service
                                    </Fragment>
                                }
                                Icon={FaUserAlt}
                                value="Customer Service"
                                onSelectOption={(value) => onSelect(value)}
                                active={
                                    selected &&
                                    selected.value.includes(
                                        'Customer Service'
                                    ) && { value: 'Customer Service' }
                                }
                            />
                        </Col>
                        <Col lg="2" xs="12">
                            <BuildYourCXOption
                                label={<Fragment>Tech Support</Fragment>}
                                Icon={FaCog}
                                value="Tech Support"
                                onSelectOption={(value) => onSelect(value)}
                                active={
                                    selected &&
                                    selected.value.includes('Tech Support') && {
                                        value: 'Tech Support',
                                    }
                                }
                            />
                        </Col>
                        <Col lg="2" xs="12">
                            <BuildYourCXOption
                                label={
                                    <Fragment>
                                        Back <br /> Office
                                    </Fragment>
                                }
                                Icon={FaBuilding}
                                value="Back Office"
                                onSelectOption={(value) => onSelect(value)}
                                active={
                                    selected &&
                                    selected.value.includes('Back Office') && {
                                        value: 'Back Office',
                                    }
                                }
                            />
                        </Col>
                        <Col lg="2" xs="12">
                            <BuildYourCXOption
                                label={<Fragment>Other</Fragment>}
                                Icon={FaBorderAll}
                                value="Other"
                                onSelectOption={(value) => onSelect(value)}
                                active={
                                    selected &&
                                    selected.value.includes('Other') && {
                                        value: 'Other',
                                    }
                                }
                            />
                        </Col>
                    </CenterAligned>
                </Row>
            </SelectionWrapper>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    height: 100%;
    width: 100%;
`;
const CenterAligned = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    ${Media('tabletlandscape')} {
        display: block;
        padding: 0px 30px;
        max-height: calc(100vh - 505px);
        overflow: auto;
    }
`;
const SelectionWrapper = styled.div`
    padding-top: 50px;
    ${Media('xlscreens')} {
        font-size: 1.42vw;
        padding: 2.63vw 0vw;
    }
    ${Media('tabletlandscape')} {
        padding-top: 0px;
    }
`;
export default Step2;
