import styled from 'styled-components';
import Media from 'shared-components/theme/medias';

export * from './industry-tag';
export * from './section-heading';
export * from './text';
export * from './transform-section';
export * from './lets-discuss';
export * from './amplify-section';
export * from './case-study';
export * from './big-circle';
export * from './performance-box';
export * from './case-study-section';


export const ContentCenter = styled.div`
    margin-bottom: 50px;
    text-align: center;
    ${Media('xlscreens')} {
        margin-bottom: 3.64vw;
    }
`;
