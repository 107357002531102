import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
import { gilroyBold, gilroyMedium } from 'shared-components/theme/fonts';
import Button from 'shared-components/button/button';
import ReactHtmlParser from 'react-html-parser';
import { handleMenuTarget } from 'shared-components/theme/helpers';
import { handleUrl, baseURLPage } from 'shared-components/theme/helpers';

const MobileMenu = ({ data }) => {
    const [open, setOpen] = useState(false);
    const [subMenu, setSubMenu] = useState([]);

    useEffect(() => {
        return () => {
            setOpen(false);
        };
    }, []);

    const handleMenu = (e, val) => {
        e.preventDefault();

        const newValues = [...subMenu];
        newValues[val] = !newValues[val];
        setSubMenu(newValues);
    };

    const SubMenu = ({ item }) => {
        return (
            <SubWrapper
                as="a"
                href={handleUrl(item.url)}
                target={item.target === '_blank' ? '_blank' : '_self'}
                rel={item.target === '_blank' ? 'noopener noreferrer' : 'none'}>
                <SubMenuImg>
                    <img
                        src={
                            item.menu_icon_mobile
                                ? item.menu_icon_mobile
                                : item.menu_icon
                        }
                        alt={(item.mobileIconAlt && item.mobileIconAlt) || '-'}
                    />
                </SubMenuImg>
                <p>{ReactHtmlParser(item.title)}</p>
            </SubWrapper>
        );
    };
    const SubNestedMenu = ({ item }) => {
        return (
            <SubNestedWrapper>
                <SubMenu item={item} />
                <NestedMenu>
                    {item.children.map((nested, index) => {
                        return <SubMenu item={nested} key={index} />;
                    })}
                </NestedMenu>
            </SubNestedWrapper>
        );
    };
    return (
        <Wrapper>
            <MenuBtn open={open} onClick={() => setOpen(!open)}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </MenuBtn>
            <MenuWrapper open={open}>
                <Menu>
                    {data && data.length > 0
                        ? data.map((val, index) => {
                              return val.children &&
                                  val.children.length > 0 &&
                                  index !== 2 ? (
                                  <MenuItemDropdown key={index}>
                                      <a
                                          href={handleUrl(val.url)}
                                          onClick={(e) => handleMenu(e, index)}>
                                          {ReactHtmlParser(val.title)}
                                      </a>
                                      <SubmenuWrapper
                                          open={subMenu[index]}
                                          isIndustries={
                                              val.menu_type === 'type2' && true
                                          }>
                                          {val.children.map((item, ind) => {
                                              if (
                                                  item.children &&
                                                  item.children.length > 0
                                              ) {
                                                  return (
                                                      <SubNestedMenu
                                                          item={item}
                                                          key={ind}
                                                      />
                                                  );
                                              } else {
                                                  return (
                                                      <SubMenu
                                                          item={item}
                                                          key={ind}
                                                      />
                                                  );
                                              }
                                          })}
                                      </SubmenuWrapper>
                                  </MenuItemDropdown>
                              ) : (
                                  <MenuItem key={index}>
                                      {handleMenuTarget(val)}
                                  </MenuItem>
                              );
                          })
                        : null}
                    <BuildCtxBtn>
                        <Button round={true} href={`${baseURLPage}contact-us`}>
                            Contact Us
                        </Button>
                    </BuildCtxBtn>
                </Menu>
            </MenuWrapper>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    position: relative;
    display: none;
    align-items: center;
    justify-content: flex-end;
    ${Media('tablet')} {
        display: flex;
    }
`;

const MenuBtn = styled.a`
    position: relative;
    z-index: 999;
    width: 65px;
    height: 65px;
    span {
        display: block;
        position: absolute;
        height: 3px;
        width: 30px;
        background: ${colors.gray_dark['100']};
        opacity: 1;
        left: calc(50% - 15px);
        margin-bottom: 5px;
        border-radius: 9px;
        transform: scale(1);
        transform: rotate(0deg);
        transition: all 0.3s ease-in-out;
        &:nth-child(1) {
            top: ${(props) => (props.open ? '31px' : '22px')};
            transform: ${(props) => (props.open ? 'scale(0)' : 'scale(1)')};
        }
        &:nth-child(2) {
            top: 31px;
            transform: ${(props) =>
                props.open ? 'rotate(-45deg)' : 'rotate(0deg)'};
        }
        &:nth-child(3) {
            top: 31px;
            transform: ${(props) =>
                props.open ? 'rotate(45deg)' : 'rotate(0deg)'};
        }
        &:nth-child(4) {
            top: 40px;
            transform: ${(props) => (props.open ? 'scale(0)' : 'scale(1)')};
        }
    }
`;

const MenuWrapper = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 998;
    width: 100%;
    height: 100%;
    padding: 120px 0px 0px;
    box-sizing: border-box;
    background-color: ${colors.white['100']};
    transition: all 0.5s ease;
    transform: ${(props) =>
        props.open ? 'translateX(0)' : 'translateX(100%)'};
`;

const Menu = styled.div`
    position: relative;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    padding: 0px 30px 20px;
`;

const MenuItem = styled.div`
    padding: 12px 0;
    a {
        display: inline-block;
        position: relative;
        color: ${colors.gray_dark['100']};
        font-family: ${gilroyMedium};
        font-size: 20px;
        &:hover {
            color: ${colors.pink['100']};
        }
    }
`;
const SubmenuWrapper = styled.div`
    box-sizing: border-box;
    overflow: hidden;
    max-height: ${(props) => (props.open ? '500px' : '0px')};
    transition: max-height 0.5s ease;
`;
const MenuItemDropdown = styled(MenuItem)`
    a {
        position: relative;
        &:before {
            content: '';
            position: absolute;
            border-top: 6px solid ${colors.pink['100']};
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            right: -25px;
            top: 50%;
            transform: translateY(-40%);
        }
    }

    &:nth-child(3) {
        a {
            &:before {
                display: none;
            }
        }
        ${SubmenuWrapper} {
            display: none;
        }
    }
`;

const BuildCtxBtn = styled.div`
    margin-top: 30px;
    a {
        font-family: ${gilroyBold};
    }
`;

const SubWrapper = styled.a`
    display: flex !important;
    align-items: center;
    margin-top: 20px;
    img {
        max-width: 20px;
        margin-right: 20px;
        max-height: 20px;
    }
    p {
        font-size: 16px;
        margin: 0px;
        br {
            display: none;
        }
    }
`;
const SubNestedWrapper = styled.div`
    margin-top: 0px;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
`;
const NestedMenu = styled.div`
    a {
        margin-top: 10px;
        margin-left: 40px;
        img {
            height: 12px;
        }
        p {
            font-size: 14px;
        }
        &:before {
            display: none;
        }
    }
    ${Media('mobile')} {
        a {
            margin-left: 15px;
            p {
                font-size: 12px;
            }
            img {
                height: 10px;
            }
        }
    }
`;
const SubMenuImg = styled.div`
    width: 40px;
`;
export default MobileMenu;
