import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { gilroyBold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';
import ReactHtmlParser from 'react-html-parser';
import { ContentCenter } from './index';
import Button from 'shared-components/button/button';

export const CaseStudy = ({
    title,
    imgUrl,
    link,
    btnTitle,
    tag,
    bgColor,
    border,
}) => {
    return (
        <Wrapper>
            <CSImage>
                <img src={imgUrl} alt="-" />
            </CSImage>
            <CaseBoxContent bgColor={bgColor} border={border}>
                <ContentCenter>
                    <CaseStudyTitle>{ReactHtmlParser(title)}</CaseStudyTitle>
                    <Button ifButton={false} round={true} href={link}>
                        {btnTitle}
                    </Button>
                </ContentCenter>
            </CaseBoxContent>
            <CaseStudyTag>{tag}</CaseStudyTag>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
`;
const CSImage = styled.div`
    height: 330px;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    ${Media('xlscreens')} {
        height: 17.18vw;
    }
`;
const CaseBoxContent = styled.div`
    background-color: ${(props) => props.bgColor};
    ${Media('xlscreens')} {
        border-right: ${(props) =>
            props.border && `2px solid ${colors.white['100']}`};
        border-left: ${(props) =>
            props.border && `2px solid ${colors.white['100']}`};
    }
`;
const CaseStudyTitle = styled.h2`
    font-size: 26px;
    font-family: ${gilroyBold};
    line-height: 1.36;
    letter-spacing: -0.38px;
    color: ${colors.white['100']};
    margin-top: 50px;
    ${Media('xlscreens')} {
        font-size: 1.82vw;
        margin-top: 2.6vw;
    }
    ${Media('mobile')} {
        font-size: 20px;
    }
`;
const CaseStudyTag = styled.div`
    top: 0;
    left: 0;
    padding: 15px 40px;
    color: ${colors.white['100']};
    font-size: 14px;
    font-family: ${gilroyBold};
    background-color: ${colors.gray_dark['100']};
    position: absolute;
    letter-spacing: 2px;
    ${Media('xlscreens')} {
        padding: 0.78vw 2.08vw;
        font-size: 0.83vw;
        letter-spacing: 0.2vw;
    }
`;
