import React from 'react';
import styled from 'styled-components';
import { gilroySemibold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';

const SubHeading = ({ content }) => {
    return <Wrapper>{content}</Wrapper>;
};
const Wrapper = styled.div`
    text-align: center;
    font-size: 27px;
    font-family: ${gilroySemibold};
    padding: 70px 0px;
    ${Media('xlscreens')} {
        font-size: 1.42vw;
        padding: 3.68vw 0vw;
    }
    ${Media('tabletlandscape')} {
        padding: 20px 15px;
    }
    ${Media('mobile')} {
        font-size: 18px;
        br {
            display: none;
        }
    }
`;
export default SubHeading;
