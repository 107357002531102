import React from 'react';
import styled from 'styled-components';
import { Row, Col } from '@bootstrap-styled/v4';
import BuildYourCXInfo2 from '../buildyourCX-info2/buildyourCX-info2';
import BuildYourCXOption from '../buildyourCX-option/buildyourCX-option';
import SubHeading from '../buildyourCX-subheading/buildyourCX-subheading';
import Media from 'shared-components/theme/medias';
import { baseURL } from 'shared-components/theme/helpers';

const Step4 = ({ onSelect, selected }) => {
    return (
        <Wrapper>
            <BuildYourCXInfo2 image={`${baseURL}assets/images/region.jpg`} />
            <SubHeading content="In what regions are you interested in operating?" />
            <Row>
                <CenterAligned>
                    <Col lg="2" xs="12">
                        <BuildYourCXOption
                            label="Phillipines"
                            image={
                                selected &&
                                selected.value.includes('Phillipines')
                                    ? `${baseURL}assets/images/phil-flag-pink.svg`
                                    : `${baseURL}assets/images/phil-flag-black.svg`
                            }
                            value="Phillipines"
                            onSelectOption={(value) => onSelect(value)}
                            active={
                                selected &&
                                selected.value.includes('Phillipines') && {
                                    value: 'Phillipines',
                                }
                            }
                        />
                    </Col>
                    <Col lg="2" xs="12">
                        <BuildYourCXOption
                            label="Nicaragua"
                            image={
                                selected && selected.value.includes('Nicaragua')
                                    ? `${baseURL}assets/images/ni-flag-pink.svg`
                                    : `${baseURL}assets/images/ni-flag-black.svg`
                            }
                            value="Nicaragua"
                            onSelectOption={(value) => onSelect(value)}
                            active={
                                selected &&
                                selected.value.includes('Nicaragua') && {
                                    value: 'Nicaragua',
                                }
                            }
                        />
                    </Col>
                    <Col lg="2" xs="12">
                        <BuildYourCXOption
                            label="Pakistan"
                            image={
                                selected && selected.value.includes('Pakistan')
                                    ? `${baseURL}assets/images/pak-flag-pink.svg`
                                    : `${baseURL}assets/images/pak-flag-black.svg`
                            }
                            value="Pakistan"
                            onSelectOption={(value) => onSelect(value)}
                            active={
                                selected &&
                                selected.value.includes('Pakistan') && {
                                    value: 'Pakistan',
                                }
                            }
                        />
                    </Col>
                    <Col lg="2" xs="12">
                        <BuildYourCXOption
                            label="Jamaica"
                            image={
                                selected && selected.value.includes('Jamaica')
                                    ? `${baseURL}assets/images/jam-flag-pink.svg`
                                    : `${baseURL}assets/images/jam-flag-black.svg`
                            }
                            value="Jamaica"
                            onSelectOption={(value) => onSelect(value)}
                            active={
                                selected &&
                                selected.value.includes('Jamaica') && {
                                    value: 'Jamaica',
                                }
                            }
                        />
                    </Col>
                    <Col lg="2" xs="12">
                        <BuildYourCXOption
                            label="US"
                            image={
                                selected && selected.value.includes('US')
                                    ? `${baseURL}assets/images/us-flag-pink.svg`
                                    : `${baseURL}assets/images/us-flag-black.svg`
                            }
                            value="US"
                            onSelectOption={(value) => onSelect(value)}
                            active={
                                selected &&
                                selected.value.includes('US') && { value: 'US' }
                            }
                        />
                    </Col>
                </CenterAligned>
            </Row>
            <BottomImage>
                <img src={`${baseURL}assets/images/big-_cross.png`} alt="" />
            </BottomImage>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    height: 100%;
    width: 100%;
`;

const CenterAligned = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    ${Media('tabletlandscape')} {
        display: block;
        padding: 0px 30px;
        max-height: calc(100vh - 505px);
        overflow: auto;
    }
`;
const BottomImage = styled.div`
    height: auto;
    width: 350px;
    bottom: 0px;
    transform: translateY(3vw);
    img {
        height: auto;
        width: 100%;
        object-fit: cover;
    }
    ${Media('xlscreens')} {
        height: 15.78vw;
        width: 18.42vw;
    }
    ${Media('tabletlandscape')} {
        display: none;
    }
`;
export default Step4;
