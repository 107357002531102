import React from 'react';
import colors from 'shared-components/theme/colors';
import { gilroyBold } from 'shared-components/theme/fonts';
import styled from 'styled-components';
import Media from 'shared-components/theme/medias';
import { MdClose } from 'react-icons/md';
import { baseURL } from 'shared-components/theme/helpers';
import MarketoForm from 'shared-components/marketo-form/marketo-form';
import { MarketoFormDesign } from 'shared-components/theme/grid-helpers';

export const BlogSubscription = ({ show, closeSubForm, formId, page }) => {

    const closeForm = () => {
        closeSubForm();
    };

    return (
        <Wrapper show={show}>
            <Overlay onClick={closeForm} />
            <PopupBody show={show}>
                <PopupHead>
                    {page !== "team-builder" &&
                        <img
                            src={`${baseURL}assets/images/wavezero-logo.svg`}
                            alt="-"
                        />
                        || <div />
                    }
                    <CloseForm onClick={closeForm}>
                        <MdClose />
                    </CloseForm>
                </PopupHead>
                {page === "team-builder" &&
                    <PopupHeading>
                        Give us your details. We&apos;ll get in touch.
                    </PopupHeading>
                    ||
                    <PopupHeading>
                        The latest in innovative CX, <br />
                        directly to your inbox.
                    </PopupHeading>
                }
                <MarketoFormDesign halfField>
                    <MarketoForm Id={formId} />
                </MarketoFormDesign>
            </PopupBody>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: ${colors.black['50']};
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
    ${(props) => {
        if (props.show) {
            return `
                opacity:1;
                visibility:visible;
            `;
        }
    }}
`;
const Overlay = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
`;
const PopupBody = styled.div`
    background-color: ${colors.white['100']};
    padding: 50px;
    width: 30%;
    position: relative;
    z-index: 99999;
    transform: scale(0.7);
    transition: all 0.3s ease;
    border-radius: 10px;
    ${(props) => {
        if (props.show) {
            return `
                transform: scale(1);
            `;
        }
    }}
    ${Media('xlscreens')} {
        padding: 1.5vw 2.6vw;
    }
    ${Media('tablet')} {
        width: 50%;
        padding: 30px 50px;
    }
    ${Media('mobile')} {
        width: 80%;
        padding: 30px;
        max-height: 90%;
        overflow-y: scroll;
    }
`;
const PopupHead = styled.div`
    height: 40px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
        height: 100%;
    }
`;
const PopupHeading = styled.h2`
    font-family: ${gilroyBold};
    font-size: 28px;
    margin-top: 0;
    margin-bottom: 30px;
    ${Media('xlscreens')} {
        font-size: 1.5vw;
        margin-bottom: 1.5vw;
    }
    ${Media('tablet')} {
        font-size: 22px;
        br {
            display: none;
        }
    }
`;
const CloseForm = styled.div`
    cursor: pointer;
    svg {
        font-size: 30px;
        &:hover {
            color: ${colors.pink['100']};
        }
    }
    ${Media('xlscreens')} {
        svg {
            font-size: 2vw;
        }
    }
`;
