import React from 'react';

import { Para } from '../cx-pocket-guide/para';
import styled from 'styled-components';
import List from './list';
import { Col, Row } from '@bootstrap-styled/v4';
import { BlockImg } from '../imperative-cx/block-img';
import { baseURL } from 'shared-components/theme/helpers';
import Media from 'shared-components/theme/medias';

export const Point7 = () => {
    return (
        <Wrapper>
            <Row>
                <Col lg="6">
                    <TextBlock>
                        <Para regular>
                            Dream Team Modeling can ensure that you have the
                            right team in place for Day 1 success. This
                            methodology uses data-driven screening and
                            recruitment processes to narrow down the best talent
                            by creating an ideal candidate profile based on
                            specified attributes, skills, experience, and
                            similarities to your top performing agents.
                        </Para>
                        <Para regular noMarginBottom>
                            Your business intelligence team will:
                        </Para>
                    </TextBlock>
                </Col>
                <Col lg="6">
                    <BlockImg
                        imgUrl={`${baseURL}assets/images/ebook/bi-methodologies/block-img-7.jpg`}
                    />
                </Col>
            </Row>
            <List>
                <li>
                    Create a model that understands the top skills and
                    characteristics of the ideal candidate, and then filter
                    through applications to find the top candidates, and then
                    act quickly.
                </li>
                <li>
                    <span>Automate job post</span> management and candidate
                    communications to quicken the recruitment process, get
                    analytics on traffic to specific job posts, and reduce the
                    risk of losing valuable candidates, and protecting the
                    employer brand by closing the loop with every candidate.
                </li>
                <li>
                    Help you engage the right talent, leading to less employee
                    turnover and better contact center performance overall.
                </li>
            </List>
        </Wrapper>
    );
};

const Wrapper = styled.div``;

const TextBlock = styled.div`
    padding-right: 90px;
    ${Media('xlscreens')} {
        padding-right: 4.68vw;
    }
    ${Media('tablet')} {
        padding-right: 0;
        margin-top: 30px;
    }
`;
