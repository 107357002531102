import React from 'react';
import { gilroyMedium } from 'shared-components/theme/fonts';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import Media from 'shared-components/theme/medias';
const BuildYourCXOption = ({
    label,
    Icon,
    onSelectOption,
    value,
    active,
    image,
}) => {
    const handleClick = () => {
        onSelectOption(value);
    };
    return (
        <Button
            onClick={handleClick}
            active={
                active && active.value && active.value === value ? true : false
            }>
            <IconWrapper>
                {(Icon && <Icon />) || <img src={image} alt="" />}
            </IconWrapper>
            <Label>{label}</Label>
        </Button>
    );
};
const Button = styled.button`
    display: flex;
    box-shadow: none;
    border: none;
    width: 100%;
    font-family: ${gilroyMedium};
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: transparent;
    min-height: 70px;
    text-align: left;
    &:hover {
        border-bottom: 1px solid ${colors.pink['100']};
        ${Media('tabletlandscape')} {
            border: 1px solid ${colors.pink['100']};
        }
    }
    ${(props) => {
        if (props.active) {
            return `border-bottom:1px solid ${colors.pink['100']};
                    img{
                        filter:grayscale(0%);
                        svg:{
                            color:${colors.pink['100']};
                        }
                    }
                    svg{
                        color:${colors.pink['100']};
                    }
                    ${Media('tabletlandscape')}{
                        border:1px solid ${colors.pink['100']};
                    }
                    `;
        } else {
            return `border-bottom:1px solid ${colors.gray['border']};
                    svg{
                        color:${colors.black['100']};
                    }
                    ${Media('tabletlandscape')}{
                        border:1px solid ${colors.gray['border']};
                    }
                    `;
        }
    }};
    ${Media('tabletlandscape')} {
        margin: 10px 0px;
    }
    &.active {
        border-bottom: 1px solid ${colors.pink['100']};
        svg {
            color: ${colors.pink['100']};
        }
    }
`;
const IconWrapper = styled.div`
    height: 20px;
    width: auto;
    margin-right: 20px;
    svg {
        height: 100%;
        width: auto;
    }
    img {
        height: 100%;
        width: 30px;
    }
    ${Media('xlscreens')} {
        height: 1.05vw;
        margin-right: 1.05vw;
        img {
            width: 1.57vw;
        }
    }
`;
const Label = styled.div`
    font-size: 13px;
    font-family: ${gilroyMedium};
    text-align: left;
    ${Media('xlscreens')} {
        font-size: 0.68vw;
    }
    ${Media('tabletlandscape')} {
        br {
            display: none;
        }
    }
`;
export default BuildYourCXOption;
