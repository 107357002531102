import React from 'react';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';
import Media from 'shared-components/theme/medias';

export const BigCircle = ({
    imgUrl,
    top,
    right,
    bottom,
    imgHeight,
    imgWidth,
    zIndex,
}) => {
    return (
        <Wrapper
            top={top}
            right={right}
            bottom={bottom}
            imgHeight={imgHeight}
            imgWidth={imgWidth}
            zIndex={zIndex}>
            <Parallax x={['40%', '100%']}>
                <img src={imgUrl} alt="-" />
            </Parallax>
        </Wrapper>
    );
};
const Wrapper = styled.div`
    position: absolute;
    top: ${(props) => props.top && props.top};
    bottom: ${(props) => props.bottom && props.bottom};
    right: ${(props) => props.right && props.right};
    z-index: ${(props) => props.zIndex && props.zIndex};
    img {
        width: ${(props) => (props.imgWidth ? props.imgWidth + 'px' : 'auto')};
        height: ${(props) => (props.imgHeight ? props.imgHeight + 'px' : '')};
    }
    ${Media('xlscreens')} {
        img {
            width: ${(props) =>
                props.imgWidth ? props.imgWidth / 19.2 + 'vw' : 'auto'};
            height: ${(props) =>
                props.imgHeight ? props.imgHeight / 19.2 + 'vw' : ''};
        }
    }
    ${Media('tablet')} {
        display: none;
    }
`;
