import React from 'react';
import styled from 'styled-components';
import { FormGroup, Label, Textarea } from '@bootstrap-styled/v4';
import colors from '../theme/colors';
import Media from '../theme/medias';

const TextArea = (props) => {
    return (
        <FormGroupCustom>
            <TextAreaCustom value={props.value} required {...props} />
            <LabelCustom color={props.color}>{props.label}</LabelCustom>
            <Bar color={props.color}></Bar>
        </FormGroupCustom>
    );
};

const FormGroupCustom = styled(FormGroup)`
    position: relative;
    margin-top: 2rem;
    margin-bottom: 0;
`;

const LabelCustom = styled(Label)`
    position: absolute;
    top: 0.25rem;
    pointer-events: none;
    z-index: 1;
    opacity: 0.4;
    font-size: 14px;
    font-weight: normal;
    -webkit-transition: all 0.28s ease;
    transition: all 0.28s ease;
    ${Media('large')} {
        font-size: 18px;
    }
    ${(props) => {
        if (props && props.color) {
            return `color:${props.color} !important`;
        }
        else{
            return `color:${colors.gray_dark['100']}`;
        }
    }}
`;

const Bar = styled.i`
    position: relative;
    display: block;
    &:before {
        content: '';
        height: 0.125rem;
        width: 0;
        left: 50%;
        bottom: -0.0625rem;
        position: absolute;
        background: ${colors.pink['100']};
        transition: left 0.28s ease, width 0.28s ease;
        z-index: 2;
    }
    ${(props) => {
        if (props && props.color) {
            return `border-bottom:0.0625rem solid ${props.color} !important`;
        }
        else {
            return `border-bottom:0.0625rem solid ${colors.gray_dark['100']}`;
        }
    }}
`;

const TextAreaCustom = styled(Textarea)`
    display: block;
    background: none;
    padding: 0.125rem 0.125rem 0.0625rem;
    font-size: 14px;
    border-width: 0;
    border-color: transparent;
    line-height: 1.9;
    width: 100%;
    transition: all 0.28s ease;
    box-shadow: none;
    resize:none;
    &:focus,
    &:valid {
        outline: none;
        & ~ ${LabelCustom} {
            font-size: 0.8rem;
            color: ${colors.pink['100']};
            top: -1rem;
            left: 0;
            ${Media('large')} {
                font-size: 12px;
            }
        }
        & ~ ${Bar} {
            &:before {
                width: 100%;
                left: 0;
            }
        }
    }
    ${Media('large')} {
        font-size: 18px;
    }
    ${(props) => {
        if (props && props.color) {
            return `color:${props.color} !important`;
        }
        else{
            return `color:${colors.gray_dark['100']}`;
        }
    }}
`;

export default TextArea;
