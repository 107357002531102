import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import { sourceSansPro } from 'shared-components/theme/fonts';
import { FlexCentered } from 'shared-components/theme/grid-helpers';
import Media from 'shared-components/theme/medias';
import { Row, Col } from '@bootstrap-styled/v4';

export const EbookFeatureList2 = ({ list, oddBgColor }) => {
    return (
        <Wrapper oddBgColor={oddBgColor}>
            <Row>
                {list &&
                    list.length > 0 &&
                    list.map((item, index) => {
                        return (
                            <Col lg="6" key={index}>
                                <li>
                                    <Icon>
                                        <img src={item.icon} alt="-" />
                                    </Icon>
                                    <Text>{item.text}</Text>
                                </li>
                            </Col>
                        );
                    })}
            </Row>
        </Wrapper>
    );
};

const Wrapper = styled.ul`
    padding: 0px;
    margin: 0px;
    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0px;
        &:nth-child(odd) {
            background-color: ${(props) => props.oddBgColor};
        }
    }
    ${Media('xlscreens')} {
        li {
            padding: 1.2vw 0;
        }
    }
`;

const Icon = styled(FlexCentered)`
    padding-right: 20px;
    img {
        height: 70px;
        width: 70px;
    }
    ${Media('xlscreens')} {
        padding-right: 1.04vw;
        img {
            height: 3.64vw;
            width: 3.64vw;
        }
    }
    ${Media('tablet')} {
        img {
            height: 60px;
            width: 60px;
        }
    }
    ${Media('tablet')} {
        img {
            height: 40px;
            width: 40px;
        }
    }
`;

const Text = styled.div`
    flex: 1 0;
    font-size: 20px;
    line-height: 1.8;
    font-family: ${sourceSansPro};
    color: ${colors.ebook.text};
    ${Media('xlscreens')} {
        font-size: 1.2vw;
        letter-spacing: 0.02vw;
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;
