import React from 'react';
import ReactHtmlParser from 'react-html-parser';

// Day and Next day
const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];

export const handleDay = () => {
    const now = new Date();
    return days[now.getDay()];
};

export const handleNextDay = () => {
    const now = new Date();
    return days[now.getDay() + 1];
};

// Price Function
export const handlePrice = (numb) => {
    if (numb !== undefined) {
        var str = numb.toString().split('.');
        str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return str.join('.');
    }
};

// Handling Url
export const handleUrl = (url) => {
    return url.replace('/api', '').replace(/\/$/, '');
};

// Handling Url Targets
export const handleMenuTarget = (menu) => {
    if (menu.target === '_blank') {
        return (
            <a
                rel="noopener noreferrer"
                target="_blank"
                href={handleUrl(menu.url)}>
                {ReactHtmlParser(menu.title)}
            </a>
        );
    } else {
        return <a href={handleUrl(menu.url)}>{ReactHtmlParser(menu.title)}</a>;
    }
};

// Base URL
export const baseURL = process.env.REACT_APP_BASE_URL;
export const baseURLPage = process.env.REACT_APP_ROOT_URL;

export const api_end_point = process.env.REACT_APP_IBEX_API;
