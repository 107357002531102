import React from "react";
import styled from "styled-components";
export const BlockImg = ({ imgUrl }) => {
    return (
        <Wrapper>
            <img src={imgUrl} alt="-" />
        </Wrapper>
    )
}
const Wrapper = styled.div`
    width:100%;
    img{
        width: 100%;
    }
`;