import React from 'react';
import { baseURL } from 'shared-components/theme/helpers';
import { Col, Row } from '@bootstrap-styled/v4';
import colors from 'shared-components/theme/colors';
import styled from 'styled-components';
import { gilroyBold } from 'shared-components/theme/fonts';
import Media from 'shared-components/theme/medias';

import { Para } from '../cx-pocket-guide/para';
import List from './list';
import { BlockImg } from '../imperative-cx/block-img';

export const Point4 = () => {
    return (
        <Wrapper>
            <Para regular>
                DSAT analysis is the process of analyzing support tickets that
                contain low CSAT scores, such as detractor surveys, negative
                sentiments and customer verbatims. This process helps to bolster
                your QA program, as well as identify the root cause of customer
                dissatisfaction – affording contact center leaders the
                opportunity to&nbsp;
                <span>
                    better train and coach agents based on real customer data,
                </span>
                &nbsp; which in turn help to better manage performance and
                improve customer satisfaction.
            </Para>
            <Para noMarginBottom regular>
                Your business intelligence team will:
            </Para>
            <Row>
                <Col lg="7">
                    <TextBlock>
                        <List>
                            <li>
                                Collect, aggregate, and review data from your
                                customer interaction data to identify trends for
                                dissatisfaction.
                            </li>
                            <li>
                                Automate insights with DSAT analytics dashboards
                                and visualizations, so that contact center
                                leaders can easily view DSAT trends over time,
                                review real-time surveys and identify critical
                                areas for real time agent coaching and identify
                                areas for agent coaching.
                            </li>
                            <li>
                                Provide insights for contact center leaders to
                                identify areas to focus on for performance
                                management, training/coaching refinement, and
                                where there are trended friction points to
                                impact/study customer journey intervention.
                            </li>
                        </List>
                    </TextBlock>
                </Col>
                <Col lg="5">
                    <BlockImg
                        imgUrl={`${baseURL}assets/images/ebook/bi-methodologies/block-img-4.jpg`}
                    />
                </Col>
            </Row>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    p {
        span {
            background-color: ${colors.pink['15']};
            font-family: ${gilroyBold};
        }
    }
`;

const TextBlock = styled.div`
    padding-right: 90px;
    ${Media('xlscreens')} {
        padding-right: 4.68vw;
    }
    ${Media('tablet')} {
        padding-right: 0;
        margin-top: 30px;
    }
`;
