import React from 'react';
import styled from 'styled-components';
import colors from 'shared-components/theme/colors';
import {
    gilroyBold,
    gilroyMedium,
    sourceSansPro,
} from 'shared-components/theme/fonts';
import { FlexCentered } from 'shared-components/theme/grid-helpers';
import Media from 'shared-components/theme/medias';
import ReactHtmlParser from 'react-html-parser';

export const EbookFeatureList = ({
    list,
    oddBgColor,
    smallIcon,
    color,
    orderCount,
    seasonal,
    evenBgColor,
    longIconMarg,
}) => {
    return (
        <Wrapper oddBgColor={oddBgColor} evenBgColor={evenBgColor}>
            {list &&
                list.length > 0 &&
                list.map((item, index) => {
                    return (
                        <li key={index}>
                            {(item.heading && (
                                <ListHeading color={color}>
                                    {item.heading}
                                </ListHeading>
                            )) || (
                                <Icon
                                    smallIcon={smallIcon}
                                    orderCount={orderCount}
                                    seasonal={seasonal}
                                    longIconMarg={longIconMarg}
                                >
                                    <img src={item.icon} alt="-" />
                                </Icon>
                            )}
                            <Text color={color} seasonal={seasonal}>
                                {ReactHtmlParser(item.text)}
                            </Text>
                        </li>
                    );
                })}
        </Wrapper>
    );
};

const Wrapper = styled.ul`
    padding: 0px;
    margin: 0px;
    counter-reset: feature;
    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0px;
        &:nth-child(odd) {
            background-color: ${(props) => props.oddBgColor};
        }
        &:nth-child(even) {
            background-color: ${(props) =>
                props.evenBgColor && props.evenBgColor};
        }
    }
    ${Media('xlscreens')} {
        li {
            padding: 1.2vw 0;
        }
    }
    ${Media('tablet')} {
        li {
            padding-right: 10px;
            br {
                display: none;
            }
        }
    }
`;

const Icon = styled(FlexCentered)`
    margin: 0 20px;
    position: relative;
    counter-increment: feature;
    &:before {
        content: counter(feature);
        position: absolute;
        background-color: ${colors.white['100']};
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-family: ${gilroyBold};
        top: 50%;
        left: 0px;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        opacity: ${(props) => (props.orderCount ? '1' : '0')};
    }
    img {
        height: ${(props) => (props.smallIcon ? '35px' : '80px')};
        width: ${(props) => (props.smallIcon ? '35px' : '80px')};
    }
    ${Media('xlscreens')} {
        margin-right: ${(props) => (props.longIconMarg ? '3.5vw' : '2.2vw')};
        margin-left: ${(props) => (props.longIconMarg ? '5vw' : '3vw')};
        &:before {
            width: 2vw;
            height: 2vw;
        }
        img {
            height: ${(props) =>
                props.smallIcon
                    ? props.seasonal
                        ? '1.5vw'
                        : '1.82vw'
                    : props.seasonal
                    ? '3.6vw'
                    : '4.16vw'};
            width: ${(props) =>
                props.smallIcon
                    ? props.seasonal
                        ? '1.5vw'
                        : '1.82vw'
                    : props.seasonal
                    ? '3.6vw'
                    : '4.16vw'};
        }
    }
    ${Media('tablet')} {
        &:before {
            width: 30px;
            height: 30px;
            font-size: 16px;
        }
        img {
            height: ${(props) => (props.smallIcon ? '24px' : '60px')};
            width: ${(props) => (props.smallIcon ? '24px' : '60px')};
        }
    }
    ${Media('mobile')} {
        &:before {
            width: 20px;
            height: 20px;
            font-size: 12px;
        }
        img {
            height: ${(props) => (props.smallIcon ? '18px' : '40px')};
            width: ${(props) => (props.smallIcon ? '18px' : '40px')};
        }
    }
`;

const Text = styled.div`
    flex: 1 0;
    font-size: 20px;
    line-height: ${(props) => (props.seasonal ? '1.6' : '1.8')};
    font-family: ${sourceSansPro};
    color: ${(props) => (props.color ? props.color : colors.ebook.text)};
    padding-right: 20px;
    ${Media('xlscreens')} {
        font-size: ${(props) => (props.seasonal ? '1.05vw' : '1.25vw')};
        letter-spacing: 0.02vw;
        padding-right: 2.66vw;
    }
    ${Media('mobile')} {
        font-size: 14px;
    }
`;
const ListHeading = styled.h3`
    width: 30%;
    font-size: 25px;
    font-family: ${gilroyMedium};
    color: ${(props) => (props.color ? props.color : colors.ebook.text)};
    margin: 0;
    text-align: center;
    ${Media('xlscreens')} {
        font-size: 1.3vw;
    }
    ${Media('mobile')} {
        font-size: 16px;
        width: 35%;
    }
`;
